.page-wrapper {
	//overflow: hidden; //disabled because the menu items was not showing 
	position: relative;
}

.page-inner {
	min-height: 100%;
}

.page-wrapper, .page-inner {
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    width: 100%;
}

.page-content-wrapper {
	align-items: stretch;
    background-color: $page-bg;
    display: flex;
    flex: 1 1 auto;
    flex-basis: 100%;
    flex-direction: column;
    padding: 50px 0px 0px;
    max-height: 100%;
    max-width: 100%;
    min-height: 1px;
    min-width: 0;
}