.input-container {
  border: 1px solid $gray-500;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 10px;
  width: 300px;

  input {
    width: 80%;
    border: none;
  }

  span,
  input {
    vertical-align: middle;
    display: inline-block;
  }

  .icon-prefix {
    color: $page-font-color;
  }

  .icon-suffix {
    color: $color-danger;
    cursor: pointer;
  }
}
