.daterangepicker table tr td,
.daterangepicker table tr th {
  width: 38px !important;
  height: 34px !important;
}

.daterangepicker table tr td.old,
.daterangepicker table tr td.new {
  color: lighten($fusion-50, 10%);
}

.daterangepicker table tr td.active:active,
.daterangepicker table tr td.active.highlighted:active,
.daterangepicker table tr td.active.active,
.daterangepicker table tr td.active.highlighted.active,
.daterangepicker table tr td.selected,
.daterangepicker table tr td.selected.highlighted,
.daterangepicker table tr td span.active.active,
.daterangepicker table tr td span.focused {
  background-color: $primary-400;
  color: $white;
}

.daterangepicker table tr td.active:active:hover,
.daterangepicker table tr td.active.highlighted:active:hover,
.daterangepicker table tr td.active.active:hover,
.daterangepicker table tr td.active.highlighted.active:hover,
.daterangepicker table tr td.active:active:focus,
.daterangepicker table tr td.active.highlighted:active:focus,
.daterangepicker table tr td.active.active:focus,
.daterangepicker table tr td.active.highlighted.active:focus,
.daterangepicker table tr td.active:active.focus,
.daterangepicker table tr td.active.highlighted:active.focus,
.daterangepicker table tr td.active.active.focus,
.daterangepicker table tr td.active.highlighted.active.focus,
.daterangepicker table tr td.selected:active:hover,
.daterangepicker table tr td.selected.highlighted:active:hover,
.daterangepicker table tr td.selected.active:hover,
.daterangepicker table tr td.selected.highlighted.active:hover,
.daterangepicker table tr td.selected:active:focus,
.daterangepicker table tr td.selected.highlighted:active:focus,
.daterangepicker table tr td.selected.active:focus,
.daterangepicker table tr td.selected.highlighted.active:focus,
.daterangepicker table tr td.selected:active.focus,
.daterangepicker table tr td.selected.highlighted:active.focus,
.daterangepicker table tr td.selected.active.focus,
.daterangepicker table tr td.selected.highlighted.active.focus,
.daterangepicker table tr td.selected:hover,
.daterangepicker table tr td.selected.highlighted:hover {
  background-color: $primary-600;
  color: $white;
}
.daterangepicker td.disabled, .daterangepicker option.disabled {
  text-decoration: none;
  background-color: $calendar-date-background-color-disabled;
  color: $calendar-date-color-disabled;
}
.daterangepicker .calendar-table .next,
.daterangepicker .calendar-table .prev {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    border-color: #a1a8c3;
  }
}

.daterangepicker .in-range.available {
  background-color: $warning-100;
}

.daterangepicker .off.ends.in-range.available {
  background-color: $warning-50;
}

.daterangepicker td.flight-available.available  {
  box-shadow: inset 0 0 0 1px $color-primary;
  background-color: $calendar-date-background-color;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: $color-primary;
  color: $white;
}

.daterangepicker td.start-date {
  border-radius: 10px 0 0 10px;
}
.daterangepicker td.end-date {
  border-radius: 0 10px 10px 0;
}

.daterangepicker table tr td,
.daterangepicker table tr th {
  width: 38px !important;
  height: 34px !important;
}

.daterangepicker .calendar-table table thead tr th {
  font-weight: bold;

  &.month {
    color: #a1a8c3;
  }
}

.daterangepicker .ranges li {
  font-size: $font-size-base;
  padding: 0.75rem 1rem;

  &.active {
    background-color: $primary-500;
  }
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  background: $calendar-dropdown-background-color;
  border: 1px solid $calendar-dropdown-background-color;
  padding: 2px;
  outline: 0;
  font-size: 12px;
  color: $calendar-dropdown-color;
}

input[daterangepicker] {
  font-family: Roboto;
  font-size: 16px !important;
  font-weight: 500;
}
.form-control[daterangepicker] {
  font-family: Roboto;
  font-size: 14px !important;
  font-weight: 400;
}

.form-control[daterangepicker].builder-text  {
  font-family: Roboto;
  font-size: 16px !important;
  font-weight: 500;
}

.daterangepicker {
  background-color: $calendar-background-color;
}
.daterangepicker .calendar-table {
    background-color: $calendar-background-color;
    color: $calendar-color;
} 

.daterangepicker td, 
.daterangepicker td.off {
  background-color: $calendar-date-background-color;
}
