.primary-nav { 
	-webkit-backface-visibility: hidden; 
	backface-visibility: hidden;

	.nav-menu:last-of-type {
		margin: 0;
	}

	.nav-menu:first-of-type {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}	
}

.nav-title {
	text-transform: uppercase;
	margin:0;
	color: $nav-title-color !important;
	padding: 0.625rem $nav-padding-x;
	margin-top: 0;
	font-size: rem($nav-title-font-size);
	letter-spacing: 1px;
	font-weight: 500 !important;
}

.nav-menu {
	
	list-style: none;
	margin: 0;
	
	padding: 0;
	
	
	a,
	a > [class*='fa-'],
	a > .#{$cust-icon-prefix} {
		@include transition(0.3s,ease-out);
	}


	b.collapse-sign {

		display: flex;
		align-items: center;
		justify-content: center;

		color: $primary-400;

		> [class*='fa-'],
		>.#{$cust-icon-prefix} {
			font-size: $nav-collapse-sign-font-size;
    	}
	}

	ul {
		padding-left: 0;
		list-style: none;
		display:none;
	}

	li {
		position: relative;

		&.open {
			> a {
				@include text-contrast($nav-background);
			}
		}

		&.active {

			> a {
				@include text-contrast($nav-background);
				background-color: rgba($white, 0.04);
				@include box-shadow(inset 3px 0 0 $color-primary);
				font-weight: 400;

				&:hover {

					>[class*='fa-'],
					>.#{$cust-icon-prefix} {
					    color: $nav-icon-hover-color;
					}

				}

			}

			> ul {
				display: block;
			}

			/* arrow that appears next to active/selected items */
			&:not(.open) > a:before {
				content: '\f413';
				font-family: 'nextgen-icons';
				position: absolute;
				top: calc(50% - 5px);
				right: 11px;
				font-size: 7px;
				height: 10px;
				width: auto;
				color: #24b3a4;
				display: flex;
				align-content: center;
				align-items: center;
			}
		}

		a {
			display: flex; /*new*/
			align-items:center; /*new*/
			outline: 0;
			padding: $nav-padding-y $nav-padding-x;
			font-size: rem($nav-font-link-size);
			color: $nav-link-color;
			font-weight: 400;
			text-decoration: none;

			position: relative; /* needed for mod-nav-hiarchiy*/

			.dl-ref {
				font-size: $nav-dl-font-size;
				text-align: center;
				min-width: $nav-dl-width;
				display: inline-block;
				border-radius: $border-radius;
				letter-spacing: 0.5px;
				margin-left: -$nav-dl-margin-left;
				margin-right: $nav-dl-margin-right;
				font-weight: 500;
				overflow:hidden;
				padding: 0 4px;
				-webkit-font-smoothing: subpixel-antialiased;

				&.label {
					margin-left: 0;
					margin-right: 0;
					font-weight: 400;
					color: rgba(255,255,255,0.7);
				}
			}

			>[class*='fa-'],
			>.#{$cust-icon-prefix} {
				margin-right: $nav-icon-margin-right;
				font-size: $nav-font-icon-size;
				width: $nav-icon-width;
			    color:$nav-icon-color;
			} 
			color: $nav-text-color;
			> .nav-link-text {

				flex: 1; /*new*/
				display: inline-flex;
				align-items:center;
				line-height: normal;
				
			}
			&.collapsed {
				.nav-menu-btn-sub-collapse {
					@include rotate(180);
				}
			}

			&:not(.nav-airline-logo):hover {
				@include text-contrast(rgba(lighten($nav-base-color, 1%), math.div(80, 100)));
				text-decoration: none;
				background-color: $nav-link-hover-background-color;
				.badge {
					color: $nav-badge-color;
				}

				>[class*='fa-'],
				>.#{$cust-icon-prefix} {
					color:$nav-icon-hover-color;
				}
				
				> .badge {
					box-shadow: 0 0 0 1px rgba(lighten($nav-base-color, 1%), math.div(80, 100));
					border: 1px solid rgba(lighten($nav-base-color, 1%), math.div(80, 100));
				}
			}
			// &:hover:not(.nav-airline-logo) {
			// 	background-color: $white;
			// }
			&:focus {
				@include text-contrast( rgba(darken($nav-base-color, 5% ), math.div(50, 100)) );

				.badge {
					color: $nav-badge-color;
				}
			}
			> img {
				height: $nav-icon-height;
				width: $nav-icon-width;
				margin-right: $nav-icon-margin-right;
			}
			> .nav-minimize {	
				margin-right: $nav-icon-margin-right;
				margin-top: 5px;
				color: $nav-toster-color;
			}
		} 

		// Sub nav level 1
		> ul {
			background-color: rgba($black,0.1);
			padding-top: 10px;
			padding-bottom: 10px;

			li {

				a {
					color: darken($nav-link-color, 5%);

					padding: $nav-padding-y $nav-padding-x $nav-padding-y $nav-padding-x + $nav-icon-width + $nav-icon-margin-right;

					b.collapse-sign {
						>[class*='fa-'],
						>.#{$cust-icon-prefix} {
							font-size: $nav-collapse-sign-font-size - 2; 
						}
					}

					>[class*='fa-'],
					>.#{$cust-icon-prefix} {
						margin-left: -2.1875rem;
						margin-right: $nav-dl-margin-right;
						color: $nav-icon-color;
						font-size: $nav-font-icon-size-sub - 0.25rem;
						width: $nav-dl-width;
						text-align: center;
					}
					> .badge {
						color: $nav-badge-color;
						background-color: $nav-badge-bg-color;
						border: 1px solid darken($color-fusion, 0%);

					}
					
					&:hover {
						@include text-contrast(rgba(($black), math.div(10, 100)));
						background-color: rgba(($black), math.div(10, 100));

						> .nav-link-text {
							>[class*='fa-'],
							>.#{$cust-icon-prefix} {
							    color:$nav-icon-hover-color;
							}
						}
					}
				}

				&.active {
					
					> a {
						@include text-contrast(rgba(lighten(darken($nav-base-color, 11%), 5%), math.div(45, 100)));

						background-color:transparent;
						box-shadow:none;

						font-weight: 400;

						> .nav-link-text {
							>[class*='fa-'],
							>.#{$cust-icon-prefix} {
							    @include text-contrast(rgba(lighten(darken($nav-base-color, 11%), 5%), math.div(45, 100)));
							}
						}	

						&:hover {
							> .nav-link-text {
								>[class*='fa-'],
								>.#{$cust-icon-prefix} {
								    color:$nav-icon-hover-color;
								}
							}			
						}
					}
				}

				&:last-child {
					> ul {
						padding-bottom:0;
					}
				}

				// Sub nav level 2
				> ul {


					li {

						&.active {
							> a {
								@include text-contrast( rgba(lighten(darken($nav-base-color, 11%), 5%), math.div(20, 100)) )
							}
						}

						a {
							color: darken($nav-link-color, 7%);
							padding: $nav-padding-y $nav-padding-x $nav-padding-y ($nav-padding-x + $nav-icon-width + $nav-icon-margin-right + 0.75rem);

							.dl-ref {
								margin-left: 0;
								margin-right: math.div($nav-icon-margin-right, 1.2);
							}
							
							>[class*='fa-'],
							>.#{$cust-icon-prefix} {
								margin-left: 0;
								margin-right: math.div($nav-icon-margin-right, 1.2);
							}
							
							&:hover {
								
								@include text-contrast( rgba(lighten(darken($nav-base-color, 11%), 5%), math.div(45, 100)) )
							}

							> .badge {
								color: $nav-badge-color;
								background-color: $nav-badge-bg-color;
								border: 1px solid darken($color-fusion, 0%);
							}

						}
					}
				}
			}
		} 

		&:last-child {
			margin-bottom:0;
		}
	}

	&:last-child {
		margin-bottom:0;
	}
} 

/* nav clean elements */
.nav-menu-clean {
	background:$white;
	
	ul {
		background: transparent !important;
		padding-bottom: 0 !important;
	}

	li {
		a {
			background: transparent !important;
			color: $fusion-500 !important;
			span {
				color: $fusion-500 !important;
			}

			&:hover {
				background-color: #f4f4f4 !important;
			}
		}	
	}

	li a {
		border-bottom: 1px solid transparent;
	}

	li > ul li > ul > li:not(:last-child) a {
		border-bottom: none;
	}
}

/* nav bordered elements */
.nav-menu-bordered {
	border: 1px solid $card-border-color;

	li a {
		border-bottom: 1px solid $card-border-color;
	}

	li > ul li > ul > li:not(:last-child) a {
		border-bottom: none;
	}

}

/* nav compact elements */
.nav-menu-compact {
	li a { padding-left: 1.5rem !important; padding-right: 1.5rem !important;}
	li li a { padding-left: 2rem !important; }
	li li li a { padding-left: 2.5rem !important; }
}

.nav-menu.nav-menu-reset {
	li a:not(:hover),
	.collapse-sign {
		color: rgba(255,255,255,0.7) !important;
	}
}


@include media-breakpoint-up(xl) {
	
	.page-sidebar {

		.primary-nav {

			.nav-menu {

				> li {

					> a {
						font-size: rem($nav-font-link-size);
					}

				}
			}
		}
	}
}
.nav-main-tab {
	background-color: $nav-background;
	border-bottom: 1px solid $nav-tabs-border-color !important;
	position: fixed;
	width: 100%;
	z-index: 11;
	> li {
		> a {
			padding: 4px 15px !important;
			height: 45px;
			width: 200px;
			border-radius: 0px !important;
			border-color: $nav-tabs-link-inactive-border-color !important;
			border-width: 0px 1px 0px 0px !important;
			&.active {
				border-color: $nav-tabs-link-active-border-color !important;
			}
			span {
				font-size: rem($fs-base + 3);
				color: $nav-text-color;
			}
			> .nav-main-tab-text {
				white-space: nowrap;
				width: 150px;
				-webkit-mask-image: linear-gradient(89.94deg, #838282 78.58%, transparent);
			}
			> .nav-main-tab-close {
				left: 90%;
			}
		}
	}
	> .nav-main-tab-all-button {
		position: fixed;
		right: 0;
		width: 230px;
		transition: all 0.145s ease-out;
		a {
			span {
				font-weight: bold;
				color: $color-primary;
			}	
			img {
				height: 1.438rem;
				width: 1.438rem
			}
			>.nav-main-tab-close-all {
				cursor: pointer;
				font-size: 29px;
				left: 62%;
				padding-top: 5px;
				width: 35px;
				
			}
			>.nav-main-tab-favorite {
				cursor: pointer;
				left: 77%;
				padding-top: 13px;
			}
		}
		>.nav-main-tab-button-seperator {
			background-color: $nav-tabs-action-button-seperator;
			height: 22px;
			left: 74%;
			width: 1px;
			top: 16px;
		}
	}
}
.nav-header-text {
	cursor: default
}
