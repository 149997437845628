.alertbar {
    color:$alert-bar-color;
    background-color:$alert-bar-background-color;
    opacity:0.8;
    padding:10px 30px;
    position: fixed;
    width:100%;
    z-index:101;
    margin-top: 19px;
    > i > span {
                font-family: $page-font;
                font-weight: $alert-bar-font-weight;
                font-size: $fs-xl;
                position: absolute;
                margin-top: 5px;
                margin-left: 10px;
            }
    &.warning {
        color: $alert-bar-warning-color;
        background-color: $alert-bar-warning-background-color;
    }
    &.information {
        color: $alert-bar-color;
        background-color: $color-success;
    }
    &.success {
        color: $alert-bar-color;
        background-color: $alert-bar-background-success-color;
    }    
}
.alertbar-main {
    .alertbar {
        margin-top: 5px;
    }
}