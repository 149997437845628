.status-dropdown-active{
  .select2-selection{
    @extend .status-dropdown-selection;
    .select2-selection__rendered {
      @extend .status-dropdown-badge;
      background-color: $status-dropdown-color-active;
    }  
  }
}

.status-dropdown-inactive{
  .select2-selection{
    @extend .status-dropdown-selection;
    .select2-selection__rendered {
      @extend .status-dropdown-badge;
      background-color: $status-dropdown-color-inactive;
    }
  }
}

.status-dropdown-deleted{
  .select2-selection{
    @extend .status-dropdown-selection;
    .select2-selection__rendered {
      @extend .status-dropdown-badge;
      background-color: $status-dropdown-color-delete;
    }
  }
}

.status-dropdown-active-form{
  .select2-selection{
    @extend .status-dropdown-selection-form;
    .select2-selection__rendered {
      @extend .status-dropdown-badge;
      background-color: $status-dropdown-color-active;
    } 
  }
}

.status-dropdown-inactive-form{
  .select2-selection{
    @extend .status-dropdown-selection-form;
    .select2-selection__rendered {
      @extend .status-dropdown-badge;
      background-color: $status-dropdown-color-inactive;
    }
  }
}

.status-dropdown-deleted-form{
  .select2-selection{
    @extend .status-dropdown-selection-form;
    .select2-selection__rendered {
      @extend .status-dropdown-badge;
      background-color: $status-dropdown-color-delete;
    }
  }
}

.status-dropdown-badge {
  width: 50%;
  max-width: 77px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  height: 25px; 
  color: $status-dropdown-color !important;
  border-radius: 25px;
  font-size: 100%;
  float: left;
  text-align: center;
  margin-top: 6px;
  line-height: 25px !important;
}

.status-dropdown-selection{
  border: none !important;
  background: none !important;
}

.status-dropdown-selection-form{
  border:inherit;
}

.status-dropdown-selector{
  .select2-results{
    padding: 0;
    .select2-results__option{
      padding-left: 15px;
      padding-right: 15px;
      line-height: 25px !important;
      height: 40px;
      &:last-child {
        border-top: $status-dropdown-line;
      }
    }
  }
}

.status-dropdown-selector-form{
  .select2-results{
    padding: 0;
    .select2-results__option{
      padding-left: 15px;
      padding-right: 15px;
      line-height: 25px !important;
      height: 40px;
    }
  }
}

.state-dropdown-selector {
  .select2-results{
    padding: 0;
    .select2-results__option{
      padding-left: 20px;
      padding-right: 20px;
      line-height: 25px !important;
      height: 40px;
    }
  }
}

.statecode span.select2-selection__rendered {
  width:70px!important;
}