.ui-tree {
  width: 99% !important;
  border: none !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.hidetree {
  display: none !important;
}

/*--------------------- start activetreefolderopen-------------------------*/
.fa-activetreefolderopen {
  position: relative;

  &:before {
    position: relative;
    color: black !important;
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: '';
    background: #629c44;
    border-top-right-radius: 100%;
    border-top-left-radius: 30%;
    border-bottom-right-radius: 60%;
    border-bottom-left-radius: 40%;
    z-index: 0;
    top: 14%;
    left: 0%;
    width: 95%;
    height: 75%;
  }
}

.fa-activetreefolderopen ~ .ui-treenode-label.ui-state-highlight {
  background-color: #629c44 !important;
  color: white !important;
}

/*-------------------end activetreefolderopen-------------------------*/

/*--------------------- start inactivetreefolderopen-------------------------*/
.fa-inactivetreefolderopen {
  position: relative;

  &:before {
    position: relative;
    color: black !important;
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: '';
    background: #eea236;
    border-top-right-radius: 100%;
    border-top-left-radius: 30%;
    border-bottom-right-radius: 60%;
    border-bottom-left-radius: 40%;
    z-index: 0;
    top: 14%;
    left: 0%;
    width: 95%;
    height: 75%;
  }
}

.fa-inactivetreefolderopen ~ .ui-treenode-label.ui-state-highlight {
  background-color: #eea236 !important;
  color: white !important;
}

/*-------------------end inactivetreefolderopen-------------------------*/

/*--------------------- start deletetreefolderopen-------------------------*/
.fa-deletetreefolderopen {
  position: relative;

  &:before {
    position: relative;
    color: black !important;
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: '';
    background: #d43f3a;
    border-top-right-radius: 100%;
    border-top-left-radius: 30%;
    border-bottom-right-radius: 60%;
    border-bottom-left-radius: 40%;
    z-index: 0;
    top: 14%;
    left: 0%;
    width: 95%;
    height: 75%;
  }
}

.fa-deletetreefolderopen ~ .ui-treenode-label.ui-state-highlight {
  background-color: #d43f3a !important;
  color: white !important;
}

/*-------------------end Inactivetreefolderopen-------------------------*/

/*--------------------- start activetreefolderclose-------------------------*/
.fa-activetreefolderclose {
  position: relative;

  &:before {
    position: relative;
    color: black !important;
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: '';
    background: #629c44;
    border-top-right-radius: 55%;
    border-top-left-radius: 0%;
    border-bottom-right-radius: 10%;
    border-bottom-left-radius: 10%;
    z-index: 0;
    top: 12%;
    left: 0%;
    width: 100%;
    height: 75%;
  }
}

.fa-activetreefolderclose ~ .ui-treenode-label.ui-state-highlight {
  background-color: #629c44 !important;
  color: white !important;
}

/*-------------------end activetreefolderopen-------------------------*/

/*--------------------- start inactivetreefolderclose-------------------------*/
.fa-inactivetreefolderclose {
  position: relative;

  &:before {
    position: relative;
    color: black !important;
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: '';
    background: #eea236;
    border-top-right-radius: 55%;
    border-top-left-radius: 0%;
    border-bottom-right-radius: 10%;
    border-bottom-left-radius: 10%;
    z-index: 0;
    top: 12%;
    left: 0%;
    width: 100%;
    height: 75%;
  }
}

.fa-inactivetreefolderclose ~ .ui-treenode-label.ui-state-highlight {
  background-color: #eea236 !important;
  color: white !important;
}

/*-------------------end inactivetreefolderopen-------------------------*/

/*--------------------- start deletetreefolderclose-------------------------*/
.fa-deletetreefolderclose {
  position: relative;

  &:before {
    position: relative;
    color: black !important;
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: '';
    background: #d43f3a;
    border-top-right-radius: 55%;
    border-top-left-radius: 0%;
    border-bottom-right-radius: 10%;
    border-bottom-left-radius: 10%;
    z-index: 0;
    top: 12%;
    left: 0%;
    width: 100%;
    height: 75%;
  }
}

.fa-deletetreefolderclose ~ .ui-treenode-label.ui-state-highlight {
  background-color: #d43f3a !important;
  color: white !important;
}

/*-------------------end deletetreefolderopen-------------------------*/

/*--------------------- start activetreeitem-------------------------*/
.fa-activetreeitem {
  position: relative;

  &:before {
    position: relative;
    color: black !important;
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: '';
    background: #629c44;
    border-top-right-radius: 20%;
    border-top-left-radius: 5%;
    border-bottom-right-radius: 5%;
    border-bottom-left-radius: 5%;
    z-index: 0;
    top: 1%;
    left: 0%;
    width: 93%;
    height: 96%;
  }
}

.fa-activetreeitem ~ .ui-treenode-label.ui-state-highlight {
  background-color: #629c44 !important;
  color: white !important;
}

.activetreetext {
  color: #629c44 !important;
}

/*-------------------end activetreeitem-------------------------*/

/* ----------------start publishtreeitem*----------------*/
.fa-publishtreeitem {
  position: relative;

  &:before {
    position: relative;
    color: black !important;
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: '';
    background: #ccff99;
    border-top-right-radius: 20%;
    border-top-left-radius: 5%;
    border-bottom-right-radius: 5%;
    border-bottom-left-radius: 5%;
    z-index: 0;
    top: 1%;
    left: 0%;
    width: 93%;
    height: 96%;
  }
}

.fa-publishtreeitem ~ .ui-treenode-label.ui-state-highlight {
  background-color: #ccff99 !important;
  color: black !important;
}

.publishtreetext {
  color: #ccff99 !important;
}
/*--------------end publishtreeitem---------------------------------*/

/* ----------------start deletetreeitem*----------------*/
.fa-deletetreeitem {
  position: relative;

  &:before {
    position: relative;
    color: black !important;
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: '';
    background: #d43f3a;
    border-top-right-radius: 20%;
    border-top-left-radius: 5%;
    border-bottom-right-radius: 5%;
    border-bottom-left-radius: 5%;
    z-index: 0;
    top: 1%;
    left: 0%;
    width: 93%;
    height: 96%;
  }
}

.fa-deletetreeitem ~ .ui-treenode-label.ui-state-highlight {
  background-color: #d43f3a !important;
  color: #ffffff !important;
}

.deletetreetext {
  color: #d43f3a !important;
}
/*--------------end deletetreeitem---------------------------------*/

/* ----------------start inactivetreeitem----------------*/
.fa-inactivetreeitem {
  position: relative;

  &:before {
    position: relative;
    color: black !important;
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: '';
    background: #eea236;
    border-top-right-radius: 20%;
    border-top-left-radius: 5%;
    border-bottom-right-radius: 5%;
    border-bottom-left-radius: 5%;
    z-index: 0;
    top: 1%;
    left: 0%;
    width: 93%;
    height: 96%;
  }
}

.fa-inactivetreeitem ~ .ui-treenode-label.ui-state-highlight {
  background-color: #eea236 !important;
  color: white !important;
}

.inactivetreetext {
  color: #eea236 !important;
}
/*--------------end inactivetreeitem---------------------------------*/

.fa-15 {
  font-size: 1.5em;
}
.panel-hdr {
  min-height: 45px !important;
}

#panel-product-tree .panel .panel-container .panel-content {
  padding: 0px !important;
}

#panel-translation-name .panel .panel-container .panel-content {
  padding-bottom: 0px !important;
}

.btn-success.disabled,
.btn-success:disabled {
  opacity: 0.35 !important;
}

.btn.disabled,
span.disabled {
  opacity: .3 !important;
  pointer-events: none;
}

.panel-toolbar .btn.btn-panel {
  height: fit-content;
  display: flex;
  width: fit-content;
  vertical-align: middle;
  padding-left: 10px;
  align-items: center;
  color: $page-font-color;
}

.form-control.is-invalid {
  background-image: none !important;
}

.form-control.is-invalid ~ .select2-selection--single {
  border-color: $color-danger !important;
}

.form-control.is-invalid ~ .select2 .select2-selection {
  border-color: $color-danger !important;
}

.form-control.is-invalid ~ .select2 .select2-selection__rendered {
  border-color: $color-danger !important;
}

.padding-top-3 {
  padding-top: 3px !important;
}

.padding-top-5 {
  padding-top: 5px !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-15 {
  padding-top: 15px !important;
}

.padding-top-25 {
  padding-top: 25px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.padding-top-60 {
  padding-top: 60px !important;
}

.padding-bottom-5 {
  padding-bottom: 5px !important;
}

.padding-bottom-10 {
  padding-bottom: 10px !important;
}

.padding-bottom-20 {
  padding-bottom: 20px !important;
}

.padding-bottom-60 {
  padding-bottom: 60px !important;
}

.padding-bottom-64 {
  padding-bottom: 64px !important;
}

.padding-bottom-75 {
  padding-bottom: 75px !important;
}

.padding-bottom-92 {
  padding-bottom: 92px !important;
}

.padding-bottom-110 {
  padding-bottom: 110px !important;
}

.padding-bottom-220 {
  padding-bottom: 220px !important;
}

.padding-bottom-488 {
  padding-bottom: 488px !important;
}

.padding-bottom-628 {
  padding-bottom: 628px !important;
}

.padding-left-0 {
  padding-left: 0px !important;
}

.padding-left-1 {
  padding-left: 1px !important;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-left-6 {
  padding-left: 6px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-left-12 {
  padding-left: 12px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-left-37 {
  padding-left: 37px !important;
}

.padding-left-40 {
  padding-left: 40px !important;
}

.padding-left-48 {
  padding-left: 48px !important;
}

.padding-left-23 {
  padding-left: 23px !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-right-30 {
  padding-right: 30px !important;
}

.padding-right-0 {
  padding-right: 0px !important;
}

/*----------------------------Button color -----------------------*/
.btn-add {
  color: #4c7a34;
}

.btn-apply {
  color: #4c7a34;
}

.btn-cancel {
  color: #ff3823;
}

.btn-delete {
  color: #d43f3a !important;
}

.btn-edit {
  color: #0c59cf;
}
/*----------------------------End Button color--------------------*/

.builder-space-first {
  padding-left: 25px;
  padding-right: 50px;
}

.builder-space {
  padding-left: 5px;
}

.builder-text {
  color: $select2-text-color;
  background-color: $builder-text-background-color !important;
  border: 1px solid $builder-text-border-color !important;
  padding: 7px 9px 8px;
  min-height: 34px;
  font-size: 16px !important;
  font-weight: 500;
}

.builder-text:focus {
  background-color: $builder-text-background-color-focus !important;
}

.builder-text:disabled {
  background-color: $builder-text-background-color-disabled !important;
}

.builder-dropdown {
  width: auto !important;
}

.builder-invalid {
  border: 1px solid red !important;
}

.builder-invalid:focus {
  border: 1px solid red !important;
}

.builder-dropdown-invalid {
  border: 1px solid red !important;
  border-radius: 4px;
}

.builder-dropdown-invalid ~ .select2 .select2-selection {
  border: 1px solid red !important;
}

.fa-1-4x {
  font-size: 1.4em;
}

#gridDetail .dx-datagrid .dx-datagrid-rowsview .dx-row > td,
.dx-datagrid-rowsview .dx-row > tr > td {
  overflow: unset !important;
}

#gridDetail .dx-datagrid .dx-datagrid-rowsview {
  overflow: unset !important;
}

.dropdown-menu-in_grid {
  min-width: 4.5rem !important;
}

.icon-stack-sm-custom {
  font-size: 1.7em;
}

.icon-stack-md-custom {
  font-size: 2em;
}

.icon-stack-lg-custom {
  font-size: 2.4em;
}

.icon-stack-xl-custom {
  font-size: 2.7em;
}

.btn-no-padding {
  padding-top: unset !important;
  padding-bottom: unset !important;
}
/*----------------------------------------------------*/

/*------ checkbox------------------------*/
.custom-control-label {
  color: #666666 !important;
}

.custom-control-input {
  &:checked {
    ~ .custom-control-label::before {
      background-color: $primary-500;
    }
  }

  &:focus {
    ~ .custom-control-label::before {
      box-shadow: 0 0 0 0.2rem rgba(253, 57, 149, 0.25);
    }
  }
}

.btn:focus {
  box-shadow: none;
}

.btn.btn-panel:focus {
  box-shadow: none;
}
/*----------------------------------------------------------------*/

/*------------- Tree header button ------------------------------*/
.active-panel .panel-hdr .dropdown-icon-menu .header-btn-tree {
  color: #fff;
  &:hover {
    color: #0960a5;
  }

  &:focus {
    color: #0960a5;
  }
}

.header-btn-tree {
  height: 2.25rem;
  width: 3.25rem;
  vertical-align: middle;
  line-height: 2.125rem;
  margin-right: 0.9375rem;
  padding: 0 11px;
  cursor: default;
  position: relative;
}

.mouseoutreeheader {
  color: #0960a5 !important;
}

.dropdown-icon-menu.tree:hover > ul {
  display: block;
  opacity: 1;
  height: 12.75rem;
}
/*-----------------------------------------------------------------*/

/*-----------data table width config ------------------------------*/
.table-nofiller {
  width: unset !important;
}
/*-----------------------------------------------------------------*/

.dataTables_wrapper .dataTables_filter label {
  padding-bottom: 0.5rem !important;
}

/*-----------data table flight list ------------------------------*/
#dtFlightList.table th,
#dtFlightList.table td {
  font-size: 12px;
  padding-right: 0.6rem;
}
/*-----------------------------------------------------------------*/

/*-----------calendar flight list ------------------------------*/
body .fc th {
  padding: 0 !important;
}

.fc .fc-daygrid-day-top {
  display: block;
}

.fc-highlight {
  opacity: unset !important;
}

.fc .fc-daygrid-day-top {
  display: inline !important;
}

.color-white {
  color: #fff !important;
}

.fc .fc-daygrid-day-frame {
  max-height: 100px;
  position: relative;
}

.weeknumber-custom {
  //background-color: #f4f4f4;
  //background-color:rgba(208, 208, 208, 0.3);
  color: rgb(128, 128, 128);
  //font-size:12px;
}

.fc-col-header-cell-cushion {
  padding: 0px !important;
}

.fc-toolbar-title {
  font-size: 1.1875rem !important;
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: unset !important;
}

.color-black {
  color: black !important;
}

.fc-toolbar h2 {
  padding-bottom: 10px;
}

/*-----scroll   --------*/

#calendarContainer {
  width: 100%;
  height: 100%;
}

.fc-scroller-noscroller {
  height: auto !important;
  overflow: visible !important;
}

/*------- status color --------------*/
.calendaractive {
  color: #fff;
  background-color: #6fab47;
}

.calendarcancelled {
  color: #fff;
  background-color: red;
}

.calendarunknow {
  color: #fff;
  background-color: #8b8989;
}

.loadfactormorethan80 {
  background-color: #b3d4a2 !important;
  /*border-color:#82a062!important;
  border-width:1px!important;*/
}

.loadfactorlessthan20 {
  background-color: #fcd894 !important;
  /*border-color:#f6c134!important;
  border-width:1px!important;*/
}

.fontsizeoandd {
  font-size: 13px !important;
}
/*-----------calendar flight list ------------------------------*/

/*----------- grid extream filter --------------------*/

.dx-datagrid .dx-header-filter-empty {
  color: whitesmoke !important;
}

/*
.dx-datagrid-group-opened {
  text-align: left!important;
}*/

.dx-datagrid-content .dx-datagrid-table .dx-row > td {
  vertical-align: middle !important;
}
/*
[role="columnheader"]:not([aria-label="Column Date - Leg"])
{
  text-align: center!important 
}*/
/*
.dx-cell-focus-disabled {  
  text-align: center!important  
} */

#product-tree .ui-tree .ui-tree-wrapper .ui-tree-container {
  height: 615px !important;
}
.invalid-feedback {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $color-danger;
}

.invalid-feedback-rule {
  display: block;
  width: 100%;
  margin-top: -0.25rem;
  margin-bottom: 0.2rem;
  font-size: 80%;
  color: $color-danger;
}

.hidden {
  display: none;
}

.airline-logo-container {
  height: 60px;
  width: 150px;
}

.airline-logo {
  max-width: 100%;
  max-height: 100%;
}

.airline-logo-info-card {
  padding: 0 !important;
}
.oops-main-logo {
  width: 220px !important;
  height: 40px !important;
}

.oops-airline-logo {
  width: 120px !important;
  height: 40px !important;
  content: url($oops-airline-logo-img);
}


.oops-airline-logo-3x {
  width: 240px !important;
  height: 80px !important;
  content: url($oops-airline-logo-3x-img);
}

.oops-auth-logo {
  width: 80px !important;
}

.blankpage-form-field {
  position: fixed;
  top: 45%;
  left: 50%;
  width: 320px;
  height: auto;
  @include translate(-50%, -50%);

  .card {
    margin: 0;
    padding: 13.5px 20px 15.5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
}

.login-footer {
  color: #000000;
  font-size: rem($fs-nano);
}

video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background: url('#{$baseURL}/img/backgrounds/clouds.png') no-repeat;
  background-size: cover;
  transition: 1s opacity;
}

@media screen and (max-device-width: 800px) {
  html {
    background: url('#{$baseURL}/img/backgrounds/clouds.png') #fff no-repeat
     center center fixed;
  }
  #bgvid {
    display: none;
  }
}
@media screen and (min-width: 800px) {
  .page-content {
    margin-left: $nav-width;
  }
  .page-footer {
    margin-left: $nav-width;
    padding-right: $nav-width + 2;
  }
}
/*----------- ngb-pagination --------------------*/
.ngb-paging {
  background-color: $page-bg;
}

.icoCount {
  width: 14px;
  display: inlineblock;
  height: 16px;
  background: transparent;
  color: white;
  text-decoration: none;
  padding-left: 10px;
}
.icoCount:before {
  content: '';
  background: url('#{$baseURL}/img/count.png');
  background-size: cover;
  position: absolute;
  width: 14px;
  height: 16px;
  margin-left: -5px;
}

.fileInfo {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 90%;
  color: black;
}

.filePreview {
  color: $primary;
}

/*----------- Boostrap Dropdown Button ------------------------------*/

.btn.btn-sm.btn-toolbar-button {
  color: $page-font-color;
  padding: 6px 0px;
  margin-left: 15px;
  & mat-icon {
    height: 36px;
    width: 36px;
    font-size: 36px;
  }
}

.dropdown-menu.show.ng-star-inserted {
  background-color: $page-bg;
  box-shadow: none;
  border: 1px solid $page-font-color;
}

.btn-group .btn:not([class*='btn-outline-']):not(.btn-icon):not(.btn-light) {
  border: none;
}

.btn-group.disabled > #btnGroupExport {
  cursor: pointer;
}

.dx-theme-generic-typography a.dropdown-item {
  background-color: $page-bg;
  color: $page-font-color;
  &:hover {
    background-color: $datagrid-row-hover-color;
  }
}

.dx-popup-content {
  background-color: $page-bg;
  color: $page-font-color;
  width: fit-content;
  height: fit-content;
  border-radius: 4px;
  padding: 0px;
}

.dx-overlay-content.dx-popup-normal.dx-resizable {
  border: 1px solid $page-font-color;
  &.dx-popup-inherit-height {
    padding: 5px;
  }
}

.dx-popover-wrapper .dx-popover-arrow:after,
.dx-popover-wrapper.dx-popover-without-title .dx-popover-arrow:after {
  background-color: $page-bg;
  border: 1px solid $page-font-color;
}

.dx-popup-wrapper > .dx-overlay-content {
  background-color: $page-bg;
}

/*-----------------------------------------------------------------*/

/*----------- Boostrap Dropdown Button ------------------------------*/

.datagrid-custom-button {
  margin-left: 160px;
  margin-top: -50px;
  @media (max-width: 1024px) {
    margin-left: 0px;
    margin-top: 0px;
  }
}

/*-----------------------------------------------------------------*/

/*-------------------- dx checkbox ------------------------------*/

.dx-checkbox .dx-checkbox-icon,
.dx-checkbox.dx-state-readonly .dx-checkbox-icon,
.dx-checkbox .dx-checkbox-icon.dx-checkbox-checked {
  background-color: $page-bg;
  color: $page-font-color;
  border: 1px solid $page-font-color;
}
.dx-checkbox .dx-checkbox-text {
  color: $page-font-color;
}

.dx-checkbox.dx-state-focused .dx-checkbox-icon {
  border: 1px solid $page-font-color;
}

.dx-checkbox.dx-state-hover .dx-checkbox-icon {
  border: 1px solid $page-font-color;
}

.row-highlighted {
  background-color: rgba(221, 221, 221, 0.5);
}

a > mat-icon {
  color: $icon-color;
}

div.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
  text-align: center;
}
span.loading {
  margin-top: 10px;
  display: block;
  font-size: 16px;
  color: $primary-500;
}
.oops-loading {
  > img {
    width: 120px;
    height: 60px;
  }
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: $color-danger;
  font-weight: bold;
}

.oops-spinner {
  > img {
    width: 50px;
    height: 50px;
  }
  margin-top: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: $color-danger;
  font-weight: bold;
}

.oops-spinner-complete {
  > img {
    width: 110px;
    height: 110px;
  }
}

/* Categories Table */
.categories-table {
  td {
    padding: 0.58rem;
    vertical-align: middle;
  }
  font-size: 16px;
  thead {
    font-family: Roboto;
    font-weight: bold;
  }
  tbody {
    font-family: Roboto;
    font-weight: normal;
    tr:not(.disabled):hover {
      background-color: $table-row-hover-color;
      cursor: pointer;
    }
  }
  .table-active {
    background-color: $table-row-selected-color;
    td {
      background-color: inherit;
    }
  }
  .table-active:hover {
    background-color: $table-row-selected-hover-color;
  }
}

.content-sub-title {
  font-size: $font-size-lg;
  font-family: $page-font;
  border-bottom: 1.84px solid $color-primary;
  width: fit-content;
  font-weight: 700;
  padding-bottom: 10px;
}

.main-content-form-wrapper {
  display: flex;

  form,
  .section-right {
    margin-top: 30px;
  }
}

.input-field-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  > label {
    color: $page-font-color;
  }
}

.input-field-label {
  font-size: $h6-font-size;
  font-family: $page-font;
  color: $gray-600;
  font-weight: 400;
  margin-bottom: 7px;
}

.input-field {
  width: 438px;
  height: 38px;
  border: 1px solid $gray-500;
  border-radius: 4px;
  padding: 6px 10px;
}

.form-submit-wrapper {
  margin-top: 70px;
  display: flex;
  width: 438px;
  align-items: center;

  input[type='checkbox'] {
    width: 23px;
    height: 23px;
    margin-right: 14px;
  }

  button {
    display: block;
    margin-left: auto;
    margin-right: 0;
  }
  >.label {
    color: $page-font-color;
  }
}

.accept-cookies-wrapper {
  margin-top: 15px;
  display: flex;
  width: 458px;
  align-items: center;
  .label {
    color: $page-font-color;
    font-weight: 400;
  }
  input[type='checkbox'] {
    width: 23px;
    height: 23px;
    margin-right: 14px;
  }
}
.accept-cookies-description {
  margin-top: 5px;
  margin-left: 36px;
  .label {
    font-weight: normal;
  }
}
.label {
  font-family: $page-font;
  font-size: $font-size-lg;
  font-weight: 400;
  color: $gray-700;
}

.submit-btn {
  background-color: $gray-700;
  color: $white;
  font-family: $page-font;
  font-size: $font-size-lg;
}

.main-content-wrapper {
  position: relative;
  top: 70px;
  width: 100% !important;
}

.remark-text {
  display: block;
  max-width: 438px;
  font-size: $font-size-sm;
  font-family: $page-font;
  font-weight: 400;
  color: $gray-700;
  margin: 15px 0 15px 0;

  &.error {
    margin: 0 0 15px 0;
  }

  .material-icons-outlined {
    border-radius: 50%;
    margin-right: 5px;
    font-size: 12px;
    border: 1.5px solid $white;
    color: $white;
    align-items: center;
    display: inline-flex;
    vertical-align: middle;
  }
}

.remark-text-error {
  background-color: $color-danger;
  color: $white;
  padding: 5px 30px;
}

.section-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin-top: 15px;
    span {
      color: $color-primary;
      font-size: 16px;
    }
  }
}

.vertical-divider {
  border-left: 1px solid $gray-500;
  height: 248px;
  margin: 0 70px;
}

.qr-code-thumbnail-img {
  width: 150px;
}

.tab-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tab-name {
    font-family: $page-font;
    font-size: 14px;
    font-weight: 500;
  }

  .tab-close-icon {
    color: $color-danger;
  }
}

.auth-page-content {
  margin-top: 5px;
}

/* User Dropdown */
.user-dropdown-wrapper {
  .dropdown-menu.dropdown-menu-animated-right {
    @include transform(scale(1.02) translateX(-88%) !important);
  }

  .header-icon {
    &[data-toggle='dropdown'] {
      & + .dropdown-menu {
        right: auto !important;
      }
    }
  }
}

.user-dropdown-size {
  background-color: $user-dropdown-background;
  width: 442px;
  height: auto;
}

.user-dropdown {
  background-color: $user-dropdown-background;
  font-family: $page-font;
  border-radius: 4px;

  .user-dropdown-content {
    &.content-flex {
      display: flex;
      padding: 15px;
    }

    &.content-inline {
      display: inline;
    }

    .user-profile-image-wrapper {
      padding-right: 10px;

      .profile-image {
        width: 65px;
        height: 65px;
      }
    }

    .user-dropdown-item {
      &.item-body {
        margin: 15px;
      }

      &.last-item {
        margin: 15px 15px 10px 15px;
      }

      span {
        display: flex;
        line-height: 24px;
        font-weight: $badge-font-weight;
        color: $user-dropdown-text-header-color;

        &.text-header {
          font-size: 20px;
          padding-top: 10px;
          color: $user-dropdown-text-header-color;
        }

        &.text-sub-header {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: $user-dropdown-text-sub-header-color;
        }
      }

      span.check-circle-icon {
        color: $user-dropdown-build-version-icon-color;
        font-size: 16.67px;
      }
    }
  }

  .user-dropdown-item-wrapper {
    width: 100%;
  }

  .user-dropdown-action-item {
    .dropdown-item {
      background-color: $user-dropdown-background;
      color: $user-dropdown-text-header-color;
      padding: 10px 15px;
      &:hover {
        background-color: $user-dropdown-action-item-hover;
      }
      &a.disabled {
        pointer-events: none;
        cursor: default;
      }
    }

    .dropdown-item:last-child {
      padding-bottom: 15px;
    }
  }
  span,
  .material-icons-outlined,
  .material-icons-sharp {
    vertical-align: middle;
    margin-right: 10px;
  }

  .text-icon-flex {
    display: flex;
  }

  .person-icon {
    height: 65px;
    width: 65px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-500;
  }

  .material-icons-outlined {
    font-size: 23px;
    &.avatar-icon {
      font-size: 36px;
    }
  }

  .dropdown-divider {
    border-top: 1px solid $user-dropdown-divider;
  }
}

.icon-wrapper-user-icon {
  height: 55px;
  .user-dropdown-toggle-icon {
      background: $gray-500;
      color: $gray-900;
      border-radius: 50%;
      font-size: 16px;
      padding: 3px;
    }
}

.master-detail-caption {
  padding: 0 0 5px 10px;
  font-size: 14px;
  font-weight: bold;
}

/* Logons History Datagrid */
.logons-history-datagrid {
  .dx-datagrid-headers .dx-header-row {
    background-color: $white;
    color: $black;
  }

  .action-button {
    margin-top: -50px;
    margin-left: 200px;
  }
}

.user-logon-history-search-bar {
  display: flex;
  justify-content: flex-end;
}

.checkbox-label {
  align-items: center;
  display: inline-flex;
  padding-left: 10px;
  font-size: $font-size-lg;
  font-family: $page-font;
}

.checkbox-container {
  display: inline-flex;
  margin-bottom: 3px;
}

.checkbox-label-icon {
  font-size: $fs-xl;
  margin-right: 8px;
  height: 16px !important;
  width: 16px !important;
}

/* Security Code */
.security-icon > span {
  padding-right: 10px;
  color: $icon-color;
}

.show-tree-btn {
  padding: 5px;
  color: $page-font-color;
  border: $input-focus-border-color solid 1px;
  border-radius: 4px;
  background-color: $page-bg;
}

/*----------- Order Dashboard ---------------------------*/
.order-dashboard.rows {
  min-width: 312px;
}
.order-dashboard.rows > .panel {
  display: inline-flex;
  min-width: 150px;
  margin-bottom: 0.75rem;
}
.order-dashboard.rows > .panel > .panel-container {
  padding: 15px;

}
.dashboard-card-header {
  font-family: roboto;
  font-size: 20px;
  font-weight: bold;
  display: flex; 
  justify-content: center;
}
.dashboard-card-description {
  margin-top: 2px;
  justify-content: center;
  display: flex;
  font-family: roboto;
  text-align: center;
}
.dashboard-grid-column-header {
  text-align: left;
}
.btn.btn-panel.trim-vertical-padding {
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

#orderDashboardSalesSummaryGrid tr.dx-header-row:nth-child(2) td[role=columnheader] {  
  text-align: left!important  
} 

#lastOrdersReceivedGrid td[role=columnheader] {  
  text-align: left!important  
} 
/*-------------------------------------------------------*/

/* Product Dashboard ------------------------------------*/
.dashboard-panel.rows {
  min-width: 352px;
}
.dashboard-panel.rows > .panel {
  display: inline-flex;
  width: 170px;
  margin-bottom: 0.75rem;
}
.dashboard-panel.rows > .panel:nth-child(2) {
  margin-left: 10px;
}
.dashboard-panel.rows > .panel > .panel-container {
  padding: 15px;

}
.dashboard-panel-statistics {
  margin-left: 0.75rem;
}

#productDashboardSummaryGrid tr.dx-header-row:nth-child(2) td[role=columnheader] {  
  text-align: left!important  
} 

.dashboard-panel-icon {
  font-size: 22px; 
  display: flex; 
  height: fit-content;
}

.mini-chart-panel {
  height: 65px; 
  width: 660px; 
  position:absolute;
  right:0; 
  margin-top: -120px; 
  margin-right:32px; 
  z-index: 1; 
}
.mini-chart-col {
  display:inline-flex;
  padding-left:10px;
}
.mini-chart-box {
  width:155px; 
  height:53px; 
  margin-top: 10px;
  padding-right:2px; 
  border-right: 1px solid $gray-500;
}
.mini-chart-title {
  font-size: 10px;
}
.mini-chart-amount-text {
  font-weight: bold; 
  font-size:12px; 
  justify-content: center;
  position:absolute;
  bottom: 0;
  color: $chart-color1;
}
.mini-chart-chart {
  width: 110px;
  height: 33px;
  float: right;
}
/* ------------------------------------------------------*/
/* Home Dashboard ---------------------------------------*/
.home-dashboard.subheader {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.home-dashboard.mini-chart-panel {
  height: 65px; 
  width: 330px; 
  position:absolute;
  right:0; 
  margin-top: -70px; 
  margin-right:24px; 
  z-index: 1; 
}

.home-dashboard-left-panel {
  position: relative;
  left: 0.75em;
  width: calc(100% - 352px - 24px);
}

@media only screen and (max-width: 600px) {
  .home-dashboard-left-panel {
    width: 100%;
    margin-right: 0px;
  }
}

.home-dashboard-left-panel .dashboard-chart {
  height: 339px;
}

.home-dashboard-left-panel .dashboard-grid {
  height: 377px;
}

.home-dashboard-right-panel {
  width: 352px;
  margin-left: 24px;
}

.home-dashboard-news-box {
  font-family: Roboto;
  margin-right: 2px;
  padding: 15px;
  border-radius: 5px;
  
  .heading {
    font-size: 16px;
    color: $news-heading;
  }
}

.light-theme .home-dashboard-news-box {
  background-color: $news-panel-background;
}

.dark-theme .home-dashboard-news-box {
  background-color: $news-panel-background;
}

.home-dashboard-news-box .news-item:not(:first-child) {
  border-top: 1px solid $gray-500;
}
.home-dashboard-news-box .news-item {
  padding-top: 15px;
  padding-bottom: 15px;
  display: inline-flex;
}
.home-dashboard-news-box .news-item > .card-img {
  width: 120px;
  height: 90px;
  object-fit: cover;
}
.home-dashboard-news-box .news-item > .card-body {
  padding-top: 0px;
  height: 90px;
  overflow: hidden;
}
.home-dashboard-news-box .news-item .card-title {
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}
.home-dashboard-news-box .news-item .card-text {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 3; 
  -webkit-box-orient: vertical;
}

/* ------------------------------------------------------*/
/*----------- Order Detail ------------------------------*/
.order-detail-container {
  font-family: $page-font;
  .order-detail-border {
    border: 1px solid lightgrey;
    padding: 20px;
  }
  .order-detail-product-text-container {
    border: 1px solid lightgrey;
    padding: 0 20px 20px 20px;
  }
  .order-detail-start-section {
    border-top: 1px solid lightgrey;
  }
  .order-detail-icon-container {
    @include make-col(0.6);
    padding: 0px;
  }
  .order-detail-icon-readonly {
    color: $icon-color;
  }
  .order-detail-icon-lock {
    color: $color-danger;
  }
  .order-detail-menu-button {
    border: none;
    background-color: $page-bg;
    color: $icon-color;
  }
  .order-detail-value {
    font-size: 16px;
    font-weight: 500;
  }
  .order-detail-comment-text {
    white-space: nowrap;
    min-width: 10%;
    max-width: 280px;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(89.94deg, #838282 78.58%, transparent);
  }
  .comment-detail-image-button {
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  .order-detail-header-sale-status-cancelled {
    color: #CE312D;
  }
  .order-detail-header-sale-status-exemption {
    color: #CE312D;
  }
  .order-detail-header-sale-status-confirmed {
    color: #4DB419;
  }
  .order-detail-header-sale-status-listed {
    color: #4DB419;
  }
  .order-detail-header-sale-status-pending {
    color: #FFA800;
  }

  .order-detail-header-payment-status-fullypaid {
    color: #4DB419;
  }
  .order-detail-header-payment-status-partlypaid {
    color: #FFA800;
  }
  .order-detail-header-payment-status-partrefund {
    color: #FFA800;
  }
  .order-detail-header-payment-status-unpaid {
    color: #FFA800;
  }
  .order-detail-header-payment-status-refund {
    color: #02249C;
  }
  .order-detail-header-payment-status-irop {
    color: #02249C;
  }

  .order-detail-header-delivery-status-blocked {
    color: #CE312D;
  }
  .order-detail-header-delivery-status-expired {
    color: #CE312D;
  }
  .order-detail-header-delivery-status-failed {
    color: #CE312D;
  }
  .order-detail-header-delivery-status-failedirop {
    color: #CE312D;
  }
  .order-detail-header-delivery-status-locked {
    color: #CE312D;
  }
  .order-detail-header-delivery-status-open {
    color: #4DB419;
  }
  .order-detail-header-delivery-status-pending {
    color: #FFA800;
  }
  .order-detail-header-delivery-status-used {
    color: #02249C;
  }
}
.order-detail-readonly-attribute {
  border: 1px solid $color-secondary;
  padding: 0.675rem;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  height: calc(1.47em + 1rem + 2px);
}
.order-detail-title-icon {
    text-shadow: $order-detail-icon-text-shadow;
}
/*----------- Order Detail ------------------------------*/
.order-shopping-party-selection {
  .number-input {
    border: 1px solid $color-secondary;
    height: 41px;
    width: 50px;
  }
}
.order-agegroup-selection-wrapper {
  width: 100px;
}

/*----------- Order Shopping ------------------------------*/
.shopping-collect-top-panel {
  height: 450px;
}
.shopping-availability-tab-wrapper {
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  .shopping-scrolling-icon {
    color: $nav-tabs-scroll-icon-color;
  }
  .shopping-availability-tab {
    min-width: 300px;
    overflow: hidden;
    .nav-tabs-fill {
      width: 1130px;
      .nav-item {
        text-align: center;
        width: calc(100% / 7);
        min-width: 146px;
        max-width: 161px;
      }
      .nav-tab-fill-value{
        font-size: 16px;
        color: #545353;
        font-family: Roboto;
        font-weight: 500;
        width: 100%;
      }
    } 
  }
}
.shopping-delete-button-list-wrapper {
  width: 35px;
}
.shopping-availability-panel {
  max-width: 1130px;
}
.shopping-availability-wrapper {
  margin-bottom: 15px;
  .fare-selection {
    justify-content: flex-start;
    padding: 0px 15px 0px 10px;
    .fare-pointer-icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .material-icons-outlined {
        position: absolute;
        align-items: end;
        color: $gray-500;
        display: flex;
        font-size: 45px;
        margin-top: 3px;
      }
    }
  }
  .order-detail-value {
    .day-change {
      color: $color-danger;
      display: inline-block;
      font-weight: 700;
      width: 50px;
    }
    .product-change {
      width: 60px;
    }
  }
}

.shopping-fare-price-detail-selected {
  border-color: $color-primary !important;
  background: $gray-400;
  margin-right: 10px;
  border-radius: 8px;
  border: 1px solid $color-primary;
  width: 110%;
  box-shadow: 0px 3px 7px 0px rgba(151, 151, 151, 0.30);

  .price-class {
    color: $gray-700;
    font-family: $page-font;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    white-space: nowrap;
  }
  .available-seat {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color:$gray-600;
  }
}
.shopping-fare-price-detail-unselected {
  border-color: $gray-500 !important;
  margin-right: 10px;
  border-radius: 8px;
  border: 1px solid $panel-border-color;
  width: 110%;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);

  .price-class {
    font-family: $page-font;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: $panel-header-color;
    line-height: 24px;
    letter-spacing: 0.15px;
    white-space: nowrap;
  }
  .available-seat {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $shopping-available-seat-text-color;
  }
  .price {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.15px;
    color: $shopping-available-price-text-color;
  }
}

.price {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  color: $color-primary;
}

.shopping-fare-description {
  margin: 10px 10px 10px 40px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  gap: 15px;
  border: 1px solid $gray-500;
  .shopping-fare-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    color: $panel-header-color;
  }
  .shopping-fare-more-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    color: $color-primary;
  }
  .shopping-fare-dynamic-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  
  .shopping-fare-dynamic-item {
    flex: 1 1 calc(50% - 16px);
    min-width: 200px;
    max-width: 100%; 
    padding: 5px;
    text-align: center;
  }
}
.shopping-fare-information {
  box-sizing: border-box;
  padding: 30px;
  gap: 15px;
  .shopping-information-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: Roboto;
    line-height: 20px;

    display: flex;
    align-items: center;
    letter-spacing: 0.25px;
    color: $panel-header-color;
  }
  .shopping-information-icon {
    color: $color-success;
  }
}
.shopping-flight-detail {
  margin: 10px 0px 10px 30px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  gap: 15px;
  border: 1px solid $gray-500;
  .shopping-detail-value{
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: $panel-header-color;
  }
  .order-detail-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    letter-spacing: 0.25px;

    color: $panel-header-color;
  }
  .order-detail-value {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;

    color: $gray-700;
  }
}
.shopping-cart {
  display: flex;
  justify-content: end;
  .cart-amount {
    display: flex;
    align-items: center;
    padding-bottom: 7px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: 500;
  }
  .cart-icon {
    font-size: 24px;
    color: $color-primary;
  }
}
.shopping-cart-detail {
  border-bottom: 1px solid lightgrey;
  margin: 10px;
}
.shopping-cart-detail-total {
  padding-top: 10px;
  margin: 10px;
}
.shopping-cart-flight-detail {
  margin: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
  gap: 15px;
  
  .order-detail-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    display: flex;
    align-items: center;
    letter-spacing: 0.25px;

    color: $order-detail-label;
  }
  .order-detail-value {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;

    color: $order-detail-value;
  }
}
.order-cart-flight-detail-border {
  border-bottom: 1px solid $gray-500;
}
.order-cart-flight-detail-border:last-child {
  border: none
}
.shopping-flight-duration {
  display: flex;
  flex-direction: row;
  align-items: center;
  .flight-line {
    width: 100%;
    position: relative;
    margin: 5px;
    border-bottom: 1px dashed $gray-600;
  }
  .flight-icon {
    position: relative;
    color: $color-primary;
    transform: rotate(90deg);
    bottom: 6px;
    left: 6px;
    .plane-icon {
      font-size: 40px;
    }
  }
  
  .circle {
    display: block;
    width: 15px;
    height: 5px;
    border-radius: 100%;
    background-color: $gray-600;
   }
}
.shopping-flight-duration-text {
  display: flex;
  align-items: center;
  justify-content: center;
  color:$gray-600;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
}
.shopping-btn-form-group-copy {
    border: 1px solid $input-border-color; 
    padding: 8px; 
    border-left: 0px;
}
.shopping-i-form-group-copy {
    font-size: 17px;
    color: $color-secondary;
}
.shopping-individual-passenger-detail {
    height: 83px;
}
.shopping-panel-header {
    margin-bottom: auto; 
    margin-top: auto; 
    line-height: 50px;
}
.shopping-tab {
  display: flex;
  justify-content: end;
  height: 45px;
}
.shopping-btn-tab {
  border-radius: 4px;
  border: 1px solid $shopping-btn-border-color;
  background: $shopping-btn-background-color;
  color: $shopping-btn-color;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  display: inline-flex;
  margin-left: 5px;
  margin-right: 5px;
}
.shopping-btn-tab:disabled{
  opacity: 50%; 
  color: $shopping-btn-color-disabled;
  border: 1px solid $shopping-btn-border-color-disabled;
  background: $shopping-btn-background-color-disabled;
  color: $shopping-btn-color-disabled;
}
.shopping-btn-tab:hover{
  border: 1px solid $shopping-btn-border-color-hover;
  background: $shopping-btn-background-color-hover;
  color: $shopping-btn-color-hover;
}
.shopping-btn-tab:focus{
  border: 1px solid $shopping-btn-border-color-focus;
  background: $shopping-btn-background-color-focus;
  color: $shopping-btn-color-focus;
}
.dx-popup-title .dx-closebutton{
  width: 47.5px;
}
.dx-popup-title .dx-closebutton .dx-icon{
  color: $color-primary;
  font-size: 30px;
}
.dx-toolbar-label .dx-toolbar-item-content{
  color:$panel-header-color;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
}

/*------------------------------------------------------*/
.text-align-right {
  text-align: right !important;
}
.text-align-left {
  text-align: left !important;
}
/*----------- Cashbook Detail --------------------------*/
.cashbook-charge-item-container {
  height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  margin-right: -16px;
  margin-top: -16px;
  margin-bottom: -16px;
}

.cashbook-detail-text {
  font-family: $page-font;
  align-content: center;
  align-self: center;

  .cashbook-detail-value {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
}

.cashbook-new-grouping {
  margin-top: -0.7rem;
  margin-bottom: -0.3rem;
}

.cashbook-detail-container {
  font-family: $page-font;
  .cashbook-detail-border {
    border: 1px solid lightgrey;
  }
  .cashbook-detail-start-section {
    border-top: 1px solid lightgrey;
    margin: 0 -16px 0 -16px;
    padding: 0 16px 0 16px;
  }
  .cashbook-detail-title {
    font-size: 14px;
    font-weight: normal;
  }
  .cashbook-detail-value {
    font-size: 16px;
    font-weight: 500;
  }
}

#gridContainerCashbook .cashbook-col-left {
  text-align: left !important;
  .dx-column-indicators {
    float: right !important;
  }
  .dx-sort-indicator{
    margin-left: 0px;
  }
}

#gridContainerCashbook .dx-datagrid-group-opened,
.dx-datagrid-group-closed {
  height: 25px;
}

.cashbook-pagination {
  margin-bottom: -2rem;
}

.header-detail {
  .panel {
    box-shadow: 0px 0px 0px 0px;
    margin-bottom: -0.25px;
    border-radius: 4px 4px 0 0;

    .panel-container {
      border-radius: 0;
    }
  }

  .panel-hdr {
    border-radius: 4px 4px 0 0;
  }
}

.body-detail {
  .panel {
    box-shadow: 0px 0px 0px 0px;
    margin-bottom: -0.25px;
    border-radius: 0px;

    .panel-container {
      border-radius: 0;
    }
  }

  .panel-hdr {
    border-radius: 0;
  }
}

.bottom-detail {
  .panel {
    box-shadow: 0px 0px 0px 0px;
    margin-bottom: -0.25px;
    border-radius: 0 0 4px 4px;

    .active-panel {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .panel-hdr {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.btn-sm.btn-cashbook {
  color: $gray-600;
  padding: 0px;
}
/*------------------------------------------------------*/

/*----------- Card Box --------------------------*/
.news-card {
  width: 270px;
  margin-right: 15px;
  font-style: Roboto;
  img {
    background-color: #bbbbbb;
    height: 175px;
    object-fit: cover;
  }
  .card-body {
    height: 165px;
    .card-title {
      font-size: 16;
      font-weight: 500;
    }
    .card-text {
      font-size: 14;
    }
  }
}

@media only screen and (max-width: 600px) {
  .news-card {
    width: 100%;
    margin-right: 0px;
  }
}

.card-footer > a.text-button-success {
  font-size: 14px;
  font-weight: 500;
  color: $success-600;
  float: left;
}
.card-footer > a.context-menu {
  float: right;
}

/*------------------------------------------------------*/

/*----------- Favorite Box --------------------------*/
.favorite-box {
  background-color: $user-dropdown-background;
  font-family: $page-font;
  border-radius: 4px;
  width: 400px;
  .favorite-box-item {
    padding: 5px 15px;
    span {
      display: flex;
      line-height: 24px;
      font-weight: $badge-font-weight;
      color: $user-dropdown-text-header-color;
      align-items: center;
    }
    &.no-padding-bottom {
      padding-bottom: 0px;
    }
  }
  .dropdown-divider {
    border-top: 1px solid $user-dropdown-divider;
  }
}

.favorite-toolbar {
  margin-left: auto;
}

.btn-favorite {
  display: inline-flex;
  padding: 5px !important;
  align-items: center;
  .material-icons {
    margin-right: 5px;
  }
}

.favorite-form-item {
  padding: 10px 0px;
  .dx-checkbox-checked .dx-checkbox-icon::before {
    margin-top: -11px;
  }
}

.not-favorite-icon {
  color: $page-font-color;
}
/*------------------------------------------------------*/
/*----------------------email---------------------------*/
.from-chip-input {
  width: 100% !important;
  margin-top: -2px;

  .mat-form-field-wrapper {
    position: static;
    width: 100% !important;
    padding: 0 !important;
  }

  .mat-form-field-flex {
    padding: 0 !important;
    border: 1px solid #adb5bd;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .mat-form-field-flex-error {
    padding: 0 !important;
    border: 1px solid $panel-active-border-color;
    border-radius: 4px;
    background-color: #ffffff;
  }

  .mat-form-field-infix {
    border: 0;
    padding: 0px !important;
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 0 0 0 0 !important;
  border: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 0 0 0 !important;
  border: 0 !important;
}

.routename {
  font-family: Roboto;
  font-size: 16px;
  padding: 15px !important;
  margin-left: 0px;
}
.pointer {
  cursor: pointer;
}
/*--------------------------------------------------------------*/
/*----------------------shopping seat---------------------------*/
.shopping-seat-treelist {
  .p-tree {
    padding: 0px !important;
  }
}

.shopping-seat-treelist-section {
  border-top: 1px solid lightgrey;
  margin: 5px 0px 10px 0px;
}

.shopping-seat-section-service-category {
    border-top: 1px solid lightgrey;
    margin: 5px 0px 10px 0px;
    width: 100% !important; 
    margin-top: 30px; 
    height: 16px;
}

.shopping-seat-title {
  display: flex;
}

.shopping-seat-color {
  color: #d3d3d3;
}

.shopping-seat .tooltip-inner {
  background-color: $tooltip-background-color;
  box-shadow: $popover-box-shadow;
  border: 1px solid $tooltip-border-color;
}

.shopping-seat .tooltip-arrow.arrow::before {
    border-top-color: $tooltip-border-color;
}

.shopping-seat-number {
  height: 30px;
  width: 30px;
  text-align: center;
  background-color: $gray-500;
  align-content: center;
  display: grid;
  border-radius: 7px;
}

/*Seat Tooltip*/
.seat-tooltip {
    width: 180px;
    padding: 0;
    background-color: $tooltip-background-color;

    .shopping-seat-number {
        height: 35px;
        width: 35px;
        text-align: center;
        background-color: $gray-500;
        align-content: center;
        display: grid;
        border-radius: 7px;
        border: 1px solid #1D1C1C;
        color: #1D1C1C;
    }

    .legend {
        color: $tooltip-seat-legend-color;
        text-align: left;
    }

    .price {
        color: $tooltip-seat-text-color;
        font-weight: 300;
        font-size: 14px;
        text-align: left;
    }

    .seat-image {
        max-width: 180px;
    }

    .seat-property-row {
        margin-top: 0.5rem;

        .seat-property {
            color: $tooltip-seat-text-color;
            text-align: left;
        }
    }

    .devider-row {
        margin-top: 0.5rem;
        display: flex;
        justify-content: center;

        .devider {
            max-width: 180px;
        }
    }
}

.shopping-label-tooltip{
    margin-left: -15px;    
    width: 30px;
    padding-top: 6px;
}

.shopping-label-section-service-category{
    width: 100%; 
    margin-top: -51px;
}


.shopping-table-td-seat-row-number{
    margin: auto; 
    width: 50%;
}


/*----------------------shopping seat---------------------------*/
/*----------------------shopping contact---------------------------*/
.shopping-contact-tree .p-tree-wrapper {
    height: 220px;
}
/*----------------------shopping contact---------------------------*/
/*----------------------order search amount display---------------------------*/
.outstanding-amount-refund {
    color: #02249C ;
}
.paid-amount-fully-paid {
    color: #4DB419;
}
/*----------------------order search amount display---------------------------*/
/*----------------------shopping payment---------------------------*/
.payment-summary-number {
    font-weight: bold;
    text-align: right!important;
}
/*----------------------shopping payment---------------------------*/
/*----------------------action bar detail---------------------------*/
.action-bar-btn {
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.25px;
    text-align: center;
    color: $black;
    background-color: $gray-400;
    margin-right: 14px;
    padding: 7px;
    white-space: nowrap;
}
.action-bar-icon {
    font-size: 18px;
    margin-right: 4px;
}
.action-bar-btn:hover {
    background-color: $action-bar-button-hover-bg;
}
.action-bar-img-icon {
    width: 18px;
    height: 18px;
}
/*----------------------action bar detail---------------------------*/
/*----------------------datagrid button---------------------------*/
.datagrid-btn {
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.25px;
  text-align: center;
  color: $color-primary;
  background-color: $gray-400;
  padding: 3.5px 7px 3.5px 7px;
  border-radius: 4px;
  border: 1px solid $gray-400;
  min-height: 32px;
  &:hover {
    border-color: $gray-500;
    color: $color-primary;
  }
  &.datagrid-btn-selected {
    background-color: $color-primary;
    color: $gray-400;
    border-color: $color-primary;
    .datagrid-btn-icon {
        font-size: 23px;
        color: $gray-400;
      }
  }
  &.black {
    color: $black;
  }
  .datagrid-btn-icon {
    font-size: 23px;
  }
  .datagrid-btn-text {
    margin-top: 2px;
    margin-left: 5px;
  }

  .datagrid-img-icon {
    margin-top: 2px;
    width: 18px;
    height: 18px;
  }
}

/*----------------------datagrid button---------------------------*/
/*-------------------------datepicker------------------------------*/
.input-date-hide-icon::-webkit-inner-spin-button,
.input-date-hide-icon::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
.input-date-hide-icon {
    border-right: 0!important;
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
}
/*-------------------------datepicker------------------------------*/

.material-icons-clickable {
  font-size: 23px;
  color: $color-primary;
  cursor: pointer;
  &.disable {
    color: $gray-700;
    opacity: 0.5;
    cursor: default;
  }
}

.material-icon-green {
  color: $color-success !important;
}

.calendar-icon {
  background-color: $calendar-icon-background-color;
  color: $calendar-icon-color;
}

/*------------------Shopping Service Image----------------------*/
.shopping-service-img {
  width: 200px!important;  
  height: 155px!important;  
  border-radius: 4px!important;  
  object-fit: contain!important;  
}

.shopping-service-border-top {
  border-top: 1px solid $shopping-service-border-color !important;
}

.shopping-add-icon {
  color: $shopping-available-add-icon-color;
}

/*--------------Shopping Voucher---------------------------------------*/
.voucher-text {
  color: $shopping-voucher-text-color!important;
  background-color: $shopping-voucher-text-background-color!important;
}

.image-container {
  width: 200px;
  height: 135px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  background: #F5F5F5;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.input-arrow-suffix {
  height: auto;
  top: 0;
  bottom: 0;
  right: 1.865rem;
  position: absolute;
  b {
    border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      height: 0;
      left: 50%;
      margin-left: -4px;
      margin-top: -2px;
      position: absolute;
      top: 50%;
      width: 0;
  }
}

/*--------------Clearable Input Text---------------------------------------*/
.input-with-clear {
    position: relative;
    display: inline-flex;
    float: right;
    width: 100%;
}

.input-clear-button {
    position: absolute;
    align-self: center;
    background-color: transparent;
    color: $color-danger;
    right: 5px;
    border: none;
    font-weight: bold;
}

.description-warning {
    .icon {
        color: $color-warning;
        font-size: 15px;
        margin-left: 10px;
        position: relative;
        top: 3px;
        width: 15px;
        height: 15px;
    }
    .text {
        color: $color-warning;
        margin-left: 5px;
    }
}
/*-----------------Font Size Textbox Custom--------------------*/
.form-control{
  font-size: 14px!important;
}
/*-----------------Font Size Textbox Custom--------------------*/
.form-control{
  font-size: 14px!important;
}

.attribute-input-text {
  min-height: 41.5px!important;
  font-size: 16px !important;
}

/*-----------------Activity Count--------------------*/
.activity-count-bar {
    margin-bottom: 20px;
    padding: 12px;
    background: $calendar-icon-background-color;
    overflow-x: auto;
    white-space: nowrap;
    cursor: default;
    border-radius: 4px;
    span {
        user-select: none;
        padding: 12px 12px 12px 0px;
        display: inline-block;
        font-size: 40px;
        vertical-align: bottom;
        &.right {
            position: absolute;
            padding: 12px 0px 12px 12px;
        }
    }
    
    ::-webkit-scrollbar,
    &::-webkit-scrollbar {
        display: none;
    }
    .activity-count {
        max-width: 87%;
        overflow-x: auto;
        white-space: nowrap;
        display: inline-block;
        .vertical-line {
            display: inline-block;
            border-left: 1px solid #d9d9d9;
            height: 67px;
            margin-right: 10px;
            vertical-align: bottom;
        }
        .sticky-left {
            background: $calendar-icon-background-color;
            display: inline-block;
            position: sticky;
            left: 0;
            z-index: 9;
        }
        .card {
            max-width: fit-content;
            padding: 5px;
            margin-right: 10px;
            border-radius: 8px;
            min-width: 84px;
            display: inline-block;
            color: $activity-count-card-font-color;
            .card-title {
                font-size: 20px;
                font-weight: 500;
            }
            .card-subtitle {
                font-size: 15px;
            }
            &.total-card {
                border: 1px solid $color-danger;
                color: $color-danger !important;
                background: $page-bg;
            }
            &.error-card {
                background: #D84E4B;
            }
            &.reject-card {
                background: #FF7050;
            }
            &.validation-card {
                background: #F5B82A;
            }
            &.inventory-sold-card {
                background: #029757;
            }
        }
    }
}
