.securitygroup-dropdown-selection + .select2-container > .selection > .select2-selection {
  border: none;
  background: none;
}

#treeListOrganisationUser > div > div > div > div > div > div > table > tbody > tr > td {
  vertical-align: middle;
}

securitygroup-dropdown > div > div >span > span > span > span.select2-selection__rendered {
  font-size: 14px;
}