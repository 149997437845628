.operational-status-nostatus {
    background-color: $status-dropdown-color-active;
    color:white;
}

.operational-status-departed {
    background-color: $status-dropdown-color-departed;
    color:white;
}

.operational-status-arrival {
    background-color: $status-dropdown-color-arrived;
    color:white;
}

.operational-status-cancelled {
    background-color: $status-dropdown-color-cancelled;
    color:white;
}

.operational-status-delayed {
    background-color: $status-dropdown-color-delayed ;
    color:white;
}

.operational-status-delivered {
    background-color: $status-dropdown-color-delivered;
    color:white;
}

.operational-status-inactive {
    background-color: $status-dropdown-color-inactive;
    color:white;
    padding-right:0.7rem;
    padding-left:0.7rem;
}