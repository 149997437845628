/* #GLOBAL IMPORTS
========================================================================== */
@import '_imports/_global-import';

/* #FRAMEWORK - Structure and layout files. (**DO NOT** change order)
                DOC: you can disable unused _modules
========================================================================== */
/* contains root variables to be used with css (see docs) */
@import '_modules/_root';
/* resets DOM elements to its natural state */
@import '_modules/_reset';
/* html and body base styles */
@import '_modules/_body';
/* app header */
@import '_modules/_page-header';
/* app logo */
@import '_modules/_page-logo';
/* app search */
@import '_modules/_page-search';
/* icon menu with user options */
@import '_modules/_dropdown-icon-menu';
/* dropdown notification in the app header */
@import '_modules/_dropdown-notification';
/* icon menu with stacked icons located in the app header */
@import '_modules/_dropdown-app-list';
/* app header stays fixed */
@import '_modules/_header-function-fixed';
/* app far left panel */
@import '_modules/_left-panel';
/* app navigation */
@import '_modules/_nav';
/* app navigation filter */
@import '_modules/_nav-listfilter';
/* app info card inside navigation */
@import '_modules/_nav-info-card';
/* app navigation made horizontal */
@import '_modules/_nav-function-top';
/* app navgation stays hidden */
@import '_modules/_nav-function-hidden';
/* app navigation stays fixed */
@import '_modules/_nav-function-fixed';
/* app navigation stays minified */
@import '_modules/_nav-function-minify';
/* app navigation footer */
@import '_modules/_nav-footer';
/* app wrapper */
@import '_modules/_page-wrapper';
/* app content heading */
@import '_modules/_page-heading';
/* app content */
@import '_modules/_page-content';
/* app footer */
@import '_modules/_page-footer';
/* app error page */
@import '_modules/_page-error';
/* seatmap */
@import '_modules/_page-seatmap';
/* shopping individual */
@import '_modules/_page-shopping-individual';
/* various app components (see docs for the full list) */
@import '_modules/page-components-accordion';
@import '_modules/page-components-action-bar';
@import '_modules/page-components-alerts';
@import '_modules/page-components-alert-bar';
@import '_modules/page-components-badge';
@import '_modules/page-components-breadcrumb';
@import '_modules/page-components-buttons';
@import '_modules/page-components-cards';
@import '_modules/page-components-carousel';
@import '_modules/page-components-categories';
@import '_modules/page-components-datagrid';
@import '_modules/page-components-dropdowns';
@import '_modules/page-components-icon-stack';
@import '_modules/page-components-listfilter';
@import '_modules/page-components-loader';
@import '_modules/page-components-messanger';
@import '_modules/page-components-modal';
@import '_modules/page-components-operational-status';
@import '_modules/page-components-pagination';
@import '_modules/page-components-panels';
@import '_modules/page-components-popovers';
@import '_modules/page-components-progressbar';
@import '_modules/page-components-reaccom-action';
@import '_modules/page-components-securitygroup-dropdown';
@import '_modules/page-components-shortcut';
@import '_modules/page-components-side-panels';
@import '_modules/page-components-status';
@import '_modules/page-components-status-dropdown';
@import '_modules/page-components-status-operation-dropdown';
@import '_modules/page-components-tables';
@import '_modules/page-components-tabs';
@import '_modules/page-components-tooltips';
@import '_modules/page-components-tree';
@import '_modules/page-components-float-action';
@import '_modules/page-components-smart-input-field';
@import '_modules/page-components-flight-seatmap';

/* #MISC - misc styles, helpers, effects and hacks
========================================================================== */
@import '_modules/_helpers';
@import '_modules/_misc';
@import '_modules/_effects';
@import '_modules/_hack';
@import '_modules/_hack-ie';

/* #MOBILE - mobile media related styles
========================================================================== */ 
/* contains most of the responsive styles for the app */
@import '_modules/_responsive';
/* changes content colors based on ambience light source of the user (experimental) */
@import '_modules/_light-levels';

/* #FORMS (customized bootstrap form elems)
========================================================================== */ 
@import '_modules/_forms';

/* #COMPONENTS (can be removed but may or may not impact other components)
========================================================================== */ 
/*@import '_modules/_form-switches';*/
@import '_modules/_translate-3d';

/* #DEMO ELEMS - elements mostly used for demo (can be removed)
========================================================================== */ 
@import '_modules/settings-demo-incompatiblity-list';
@import '_modules/settings-demo-theme-colors';
@import '_modules/settings-demo';
@import '_modules/_demo-only';

/* #_extensions - Components imported in alphabetical order (remove extensions from directory if not needed)
========================================================================== */ 
@import '_extensions/extension-pace';
@import '_extensions/extension-slimscroll';
@import '_extensions/extension-waves';

/* #_plugins - Components imported in alphabetical order (remove plugins from directory if not needed)
========================================================================== */ 
/*@import '_plugins/_plugin-*.scss';*/

/* #ANIMATION - CSS animations and keyframes
========================================================================== */ 
@import '_modules/keyframes-general';
@import '_modules/keyframes-highlight';
@import '_modules/keyframes-spinner';
@import '_modules/keyframes-transition';

/* #MODS - Layout manipulation
========================================================================== */ 
@import '_modules/mod-bg';
@import '_modules/mod-clean-page-bg';
@import '_modules/mod-colorblind';
@import '_modules/mod-disable-animation';
@import '_modules/mod-hide-info-card';
@import '_modules/mod-hide-nav-icons';
@import '_modules/mod-high-contrast';
@import '_modules/mod-lean-page-header';
@import '_modules/mod-main-boxed';
@import '_modules/mod-nav-accessibility';
@import '_modules/mod-text-size';

/* #COLORS - we place this here so it can override other colors as needed
========================================================================== */ 
@import '_modules/_colors';

/* #APP related modules (print, fullscreen, etc)
========================================================================== */ 
@import '_modules/app-custom-scrollbar';
@import '_modules/app-fullscreen';
@import '_modules/app-print';

/* #OVERRIDE - You can override any of the variables through this file
========================================================================== */ 
@import '_modules/_overrides';