.reaccom_action {
    color:#4BD419;
    font-weight: 600;
}

.cancel_action {
    color:#CE312D;
    font-weight: 600;
}

.activity_action {
    color:#02249c;
    font-weight: 600;
}

.result_success {
    color:$color-success;
}

.result_failed {
    color:$color-danger;
}

.nav_new_zero {
    background-color:$datagrid-cell-number-equal-zero!important;
}

.nav_new_morethan_zero {
    background-color:$datagrid-cell-number-morethan-zero!important;
}

.nav_new_lessthan_zero {
    background-color:$datagrid-cell-number-lessthan-zero!important;
}

.button-filter.dx-button.dx-button-normal.dx-button-mode-outlined.dx-widget.dx-button-has-text{
    border-color: $gray-500!important;
}