.page-sidebar {
	@extend %nav-bg;
	background-color: $nav-background;
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
	height: 100%;
	max-width: $nav-width;
	position: fixed;
	top: 55px;
	width: $nav-width;
	will-change: left, right;
	z-index: $depth-nav;
	.page-sidebar-airline {
		margin: 30px 0px;
		height: 100%;
		z-index: $depth-nav;
	}
}
.page-sidebar:hover {
	overflow: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	padding-bottom: 50px;
	.page-sidebar-airline {
		background-color: $nav-background;
	}
	.page-sidebar-menu-item {
		position: sticky !important; 
		top: 30px;
	}
}
.page-sidebar::-webkit-scrollbar {
	width: 5px;               /* width of the entire scrollbar */
} 
.page-sidebar::-webkit-scrollbar-track {
	background: $nav-background;        /* color of the tracking area */
}  
.page-sidebar::-webkit-scrollbar-thumb {
	background-color: $gray-800;    /* color of the scroll thumb */
	border-radius: 15px;       /* roundness of the scroll thumb */
	border: 3px solid $nav-scroll-background-color;  /* creates padding around scroll thumb */
}