@use 'sass:math';
//Core style.
@import "../scss/_modules/variables";
@import "../scss/bootstrap.scss";
@import "../scss/app.core.scss";
//Component style
@import "../scss/app.icons.scss";
@import "../scss/_modules/dx.generic.custom-scheme.scss";
@import "../scss/app.angular.scss";
@import "../scss/plugins/select2/select2-cust.scss";
@import "../scss/plugins/select2/select2-builder-green.scss";
@import "../scss/plugins/select2/select2-builder-blue.scss";
@import "../scss/plugins/select2/select2-builder-gray.scss";
@import "../scss/plugins/select2/select2-builder-multiple-gray.scss";

@import "../scss/plugins/daterangepicker/daterangepicker-custom.scss";
@import "../scss/plugins/datatables/datatables.styles.app.scss";
@import "../scss/plugins/dropzone/dropzone-custom.scss";
@import "../css/plugins/datatables/datatables.bundle.css";
@import "../css/plugins/fullcalendar/fullcalendar.bundle.css";
// Modules style sheet.
@import "../scss/oops-solution.scss";
