.settings-panel {
  .expanded {
    &.theme-colors {
      display: block;
      box-shadow: none;
      border: 0;
      background: transparent;

      > ul {
        height: auto;

        > li {
          [data-action] {
            &:hover {
              opacity: 1;
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      /*@include theme-button-color ($theme-1-fusion, $theme-1-primary, $theme-1-info, $theme-1-success, $theme-1-warning, $theme-1-danger)*/

      #myapp-light {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: $white;
      }
      #myapp-dark {
        //@include theme-button-color (#434a51, #2196f3, #ac2db4, #2cb030, #ff8a1f, #f91711);
        background: $gray-800;
      }
      #myapp-sapphire {
        //@include theme-button-color ($theme-2-fusion, $theme-2-primary, $theme-2-info, $theme-2-success, $theme-2-warning, $theme-2-danger);
        background: #2198f3;
      }
      #myapp-aquamarine {
        //@include theme-button-color (#476f85, #00bbff, #8c1fad, #33a91e, #ff540a, #f50025);
        background: #6ab5b4;
      }
      #myapp-ruby {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #ff58a6;
      }

      #myapp-tapestry {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #b56a9f;
      }
      #myapp-atlantis {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #9fcb3d;
      }
      #myapp-indigo {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #4679cc;
      }
      #myapp-dodgerBlue {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #2198f3;
      }
      #myapp-tradewind {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #6ab5b4;
      }
      #myapp-cranberry {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #dd5293;
      }
      #myapp-osloGray {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #868e96;
      }
      #myapp-chetwodeBlue {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #7c91df;
      }
      #myapp-apricot {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #e59c6c;
      }
      #myapp-blueSmoke {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #778c85;
      }
      #myapp-greenSmoke {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #a2b077;
      }
      #myapp-wildBlueYonder {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #7976b3;
      }
      #myapp-emerald {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #55ce5f;
      }
      #myapp-supernova {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #ffc800;
      }
      #myapp-hoki {
        //@include theme-button-color (#5c4581, #4b00f9, #a81e39, #1da494, #abff04, #efd300);
        background: #627ca0;
      }
    }
  }
}
