.page-footer {
	align-items:center;
	background: $footer-bg;
	bottom: 0;
	color:$footer-text-color;
	display: flex;
	flex: 0 0 auto;
	font-size: rem($footer-font-size);
	height: $footer-height;
    order: 4;
    padding-left: $footer-padding;
	padding-right: $footer-padding;
	position: fixed;
	width: 100%;
	a {
		color: $footer-text-color !important;
	}
}
