.status-active {
    background-color: $status-dropdown-color-active;
    color:white;
}

.status-inactive {
    background-color: $status-dropdown-color-inactive;
    color:white;
}

.status-delete {
    background-color: $status-dropdown-color-delete;
    color:white;
}

.status-devilered {
    color: $status-dropdown-color-delivered;
}

.status-delayed {
    color: $status-dropdown-color-delayed;
}

.status-mendatory {
    background-color: $status-dropdown-color-delete;
    color:white;
}

.status-condition {
    background-color: $status-dropdown-color-inactive;
    color:white;
}