.dx-datagrid {
  background-color: $page-bg !important;
  color: $page-font-color !important;
}

.dx-row.dx-column-lines.dx-header-row {
  background-color: $page-bg !important;
  color: $page-font-color !important;
}

.dx-datagrid-borders > .dx-datagrid-filter-panel,
.dx-datagrid-borders > .dx-datagrid-headers,
.dx-datagrid-borders > .dx-datagrid-pager {
  border-top: 1px solid $datagrid-row-border-color !important;
}

.dx-datagrid-borders > .dx-datagrid-headers,
.dx-datagrid-borders > .dx-datagrid-rowsview,
.dx-datagrid-borders > .dx-datagrid-total-footer {
  border-left: 1px solid $datagrid-row-border-color !important;
  border-right: 1px solid $datagrid-row-border-color !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td:not(.freezColumn) {
  border-bottom: 1px solid $datagrid-row-border-color;
  &.upper-header-cell {
    border-bottom-width: 1px !important;
  }
}
.dx-datagrid-rowsview .dx-row {
  border-bottom: 1px solid $datagrid-row-border-color !important;
  height:40px;
}

.dx-datagrid-pager.dx-pager {
  border-top: none !important;
}

.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines
  > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused).dx-column-lines
  > tr
  > td {
  &:not(.dx-cell-danger):not(.dx-cell-warning):not(.dx-cell-success){
    color: $page-font-color !important;
  }
  
  &:not(.nav_new_zero):not(.nav_new_morethan_zero):not(.nav_new_lessthan_zero){  
    background-color: $datagrid-row-selected-color !important;
  }
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td {
    &:not(.dx-cell-danger):not(.dx-cell-warning):not(.dx-cell-success){
      color: $page-font-color !important;
    }
    &:not(.nav_new_zero):not(.nav_new_morethan_zero):not(.nav_new_lessthan_zero){  
      background-color: $datagrid-row-selected-hover-color !important;
    }
}

.dx-datagrid-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > td:not(.dx-focused):not(.dx-cell-danger):not(.dx-cell-warning):not(.dx-cell-success) {
  color: $page-font-color !important;
  background-color: $datagrid-row-hover-color !important;
}

.dx-placeholder {
  color: $page-font-color !important;
}

.dx-toolbar-items-container {
  background-color: $page-bg !important;
  color: $page-font-color !important;
}
.dx-clear-button-area {
    background-color: $page-bg !important;
  }
.dx-texteditor-input:focus {
  background-color: $datagrid-row-hover-color !important;
  color: $page-font-color !important;
}

.dx-datagrid-search-panel.dx-texteditor {
  &.dx-state-hover {
    border-color: $input-focus-border-color !important;
  }
  &.dx-state-active.dx-editor-filled,
  &.dx-state-active.dx-editor-outlined,
  &.dx-state-active.dx-editor-underlined,
  &.dx-state-focused.dx-editor-filled,
  &.dx-state-focused.dx-editor-outlined,
  &.dx-state-focused.dx-editor-underlined {
    border-color: $input-focus-border-color !important;
  }
}

.dx-button-mode-outlined .dx-icon {
  color: $page-font-color !important;
}
.dx-texteditor-input {
  background-color: $page-bg !important;
  color: $page-font-color !important;
}

.dx-pager .dx-pages .dx-page,
.dx-pager .dx-page-sizes .dx-page-size {
  background-color: $datagrid-pagnation-background-color !important;
  border-color: $datagrid-pagnation-border-color !important;
  color: $datagrid-pagnation-color !important;
  margin-left: 15px !important;
  width: 38px !important;
  height: 38px !important;
  text-align: center !important;
  padding: 8px 0px !important;
}
.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
  background-color: $datagrid-pagnation-background-color-disabled !important;
  border: 1px solid !important;
  color: $datagrid-pagnation-color-disabled !important;
  border-color: $datagrid-pagnation-border-color-disabled !important;
  margin-left: 15px !important;
  width: 38px !important;
  height: 38px !important;
  text-align: center !important;
  padding: 8px 0px !important;
}
.dx-pager .dx-pages .dx-page:hover,
.dx-pager .dx-page-sizes .dx-page-size:hover {
  background-color: $datagrid-pagnation-background-color-hover !important;
}
.dx-pager .dx-pages .dx-separator {
  color: $page-font-color
}

.dx-navigate-button {
  border-radius: 3px;
  background-color: $datagrid-pagnation-background-color;
  border: 1px solid !important;
  color: $datagrid-pagnation-color;
  border-color: $datagrid-pagnation-border-color !important;
  width: 38px !important;
  height: 38px !important;
  text-align: center !important;
  padding: 0px !important;
  &.dx-next-button {
    margin-left: 15px;
  }
  &.dx-button-disable {
    color: $datagrid-pagnation-color-disabled;
    background-color: $datagrid-pagnation-background-color-disabled;
    border-color: $datagrid-pagnation-border-color-disabled;
  }
}

.dx-button-normal.dx-button-mode-contained.dx-widget.dx-dropdowneditor-button {
  background-color: $page-bg;
}

.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon {
  background-color: $datagrid-row-selected-color;
  color: $page-font-color;
}

.dx-scrollable-container {
  background-color: $page-bg;
}

.dx-item.dx-list-item {
  color: $page-font-color;
}

.dx-device-mobile
  .dx-list:not(.dx-list-select-decorator-enabled)
  .dx-list-item.dx-list-item-selected {
  background-color: $datagrid-row-selected-color;
}

.dx-datagrid-rowsview.dx-empty {
  border-top: none !important;
}

.dx-datagrid-headers.dx-datagrid-nowrap.dx-header-multi-row {
  border-bottom: none !important;
}

.dx-datagrid .dx-column-lines > td {
  border-left-color: $datagrid-row-border-color !important;
  border-right-color: $datagrid-row-border-color !important;
}

.dx-cell-disabled {  
  background-color: $datagrid-cell-disabled; 
}

.dx-cell-editable {
  background-color: $datagrid-cell-editable; 
}

.dx-cell-danger {
  color: $color-danger !important;
}

.dx-cell-success {
  color: $color-success !important;
}

.dx-cell-warning {
  color: $color-warning !important;
}

.dx-show-clear-button .dx-icon-clear::before {
  font-family: 'Material Icons';
  font-size: 23px;
  content: 'clear';
  color: $color-primary;
}

.dx-row-selected {
  border: 2px solid $color-secondary !important;

  & > td {
    border-top: 2px solid $color-secondary !important;
    border-bottom: 2px solid $color-secondary !important;
    &:first-child {
      border-left: 2px solid $color-secondary !important;
    }
    &:last-child {
      border-right: 2px solid $color-secondary !important;
    }
  }
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row {
  border: 2px solid $color-secondary !important;
  &.dx-command-edit:not(.dx-focused):not(.invalid-cell) .dx-link, 
  & > td:not(.dx-focused):not(.invalid-cell), 
  & > tr > td:not(.dx-focused):not(.invalid-cell) {
    background-color: inherit !important;
    color: inherit !important;
    border-top: 2px solid $color-secondary !important;
    border-bottom: 2px solid $color-secondary !important;
    &:first-child {
      border-left: 2px solid $color-secondary !important;
    }
    &:last-child {
      border-right: 2px solid $color-secondary !important;
    }
  }
}

.dx-datagrid .dx-row > td.invalid-cell {
  background-color: inherit !important;
  color: inherit !important;
  border: 2px solid $color-danger !important;
}

// lookup in datagrid column
.dx-overlay-content.dx-popup-normal.dx-resizable {
  border: 1px solid $datagrid-row-border-color !important;
}

.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item {
  color: $page-font-color !important;
  &.dx-state-hover {
    background-color: $datagrid-row-hover-color !important;
  }
  &.dx-list-item-selected {
    background-color: $datagrid-row-selected-color !important;
  }
  &.dx-state-active {
    background-color: inherit !important;
  }
}

dx-data-grid.scrollable {
  max-height: 200px;
}

.dx-cell-load-80 {
  background-color: #27cc3f!important; 
}

.dx-cell-load-50 {
  background-color: #fcbb35!important; 
}

.dx-cell-load-0 {
  background-color: #fc5f57!important; 
}

.dx-cell-load-negative {
  background-color: #dcdcdc!important; 
}

.dx-datagrid-headers .dx-datagrid-table .dx-header-row > td.freezColumn {
  border-bottom: 0px!important;
}

.datagrid-with-dropdown .dx-datagrid-rowsview.dx-datagrid-nowrap {
    overflow: visible;
}

.account-summary-table {
    .dx-datagrid-scrollable-simulated.dx-datagrid-content.dx-datagrid-scroll-container {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        background-color: $datagrid-row-hover-color !important;
    }
    
    .dx-toolbar-items-container {
        height: 70px !important;
    }

    .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.color-danger-500 {
        color: $color-danger !important;
        border-radius: 4px !important;
        margin-right: 5px !important;
        &.dx-state-hover {
            background-color: $datagrid-row-hover-color !important;
        }
        &.dx-item-selected {
            background-color: $datagrid-row-selected-color !important;
        }
    }
}
.no-sort {
  .dx-sort {
    visibility: hidden !important;
  }
}
td[role=columnheader] {
  text-align: center !important
}

td[role=gridcell].uppercase {
  text-transform: uppercase;
}

.uppercase input {
  text-transform: uppercase;
}

.order-lock-datagrid {
    .dx-row.dx-column-lines.dx-header-row:last-child {
        visibility: collapse !important;
    }
}

.pending-payment {
    .dx-row.dx-column-lines.dx-header-row:last-child td:nth-last-child(-n+2) {
        display: none;
    }
}