:root {
  @each $theme-colors, $value in $theme-colors {
    --theme-#{$theme-colors}: #{$value};
  }

  @each $theme-colors-extended, $value in $theme-colors-extended {
    --theme-#{$theme-colors-extended}: #{$value};
  }
  
  @each $bp, $value in $grid-breakpoints {
    --breakpoint-#{$bp}: #{$value};
  }	

--chart-owner: #{$chart-owner};
--chart-owner-ise: #{$chart-owner-ise};
--chart-owner-api: #{$chart-owner-api};
--chart-owner-batch: #{$chart-owner-batch};
--chart-gds: #{$chart-gds};
--chart-aggregator: #{$chart-aggregator};
--chart-ota: #{$chart-ota};
--chart-travelagency: #{$chart-travelagency};
}