/*--------------- PrimeNg Tree --------------*/
.p-tree {
    background-color: $page-bg !important;
    border: none;
}

.p-treenode-icon {
    color: $page-font-color !important;
    margin-right: 10px !important;
}

.tree-view-folder-open-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'folder_open';
}

.tree-view-folder-close-icon::before {
    font-family: 'Material Icons';
    font-size: 23px;
    content: 'folder';
}

.tree-view-file-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'insert_drive_file';
}

.p-tree-toggler-icon.pi.pi-fw.pi-chevron-down::before {
    font-family: 'Material Icons Outlined';
    content: 'indeterminate_check_box';
}

.p-tree-toggler-icon.pi.pi-fw.pi-chevron-right::before {
    font-family: 'Material Icons Outlined';
    content: 'add_box';
}

.p-tree-toggler chevrondownicon.p-element.p-icon-wrapper::before {
    font-family: 'Material Icons Outlined';
    content: 'indeterminate_check_box';
}
.p-tree-toggler chevronrighticon.p-element.p-icon-wrapper::before {
    font-family: 'Material Icons Outlined';
    content: 'add_box';
}
svg.p-icon.p-tree-toggler-icon {
  display:none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    color: $page-font-color !important;

}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    box-shadow: none;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
    height: 45px;
    &.p-treenode-selectable {
        &:hover  {
            background-color: $treenode-hover-bg-color !important;
        }
        &.p-highlight {
            background-color: $treenode-selected-bg-color !important;
            color: $page-font-color !important;
            &:hover {
                background-color: $treenode-selected-hover-bg-color !important;
            }
        }
    }
}

.p-treenode-label {
    color: $page-font-color !important;
    font-size: $fs-md;
    width: 100%;
}

body .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    box-shadow: none;
}

.p-treenode-content .p-treenode-label .btn-select {
    height: 24px;
    padding: 0px 11.5px;
    margin-top: -1px;
    margin-right: 10px;
    font-size: 12px;
    box-shadow: none!important;
}

@mixin tree-conjunction-word-styles {
    margin-left: 10px;
    font-family: 'Roboto';
    font-weight: bold;
    font-style: italic;
    font-size: 12px;
}
.p-treenode-label span.conjunction {
    @include tree-conjunction-word-styles;
}

/* -------------------------------------- */

/* -------- Dx Tree View ---------- */

.dx-treeview-node {
    &.dx-state-hover > .dx-item.dx-treeview-item {
        background-color:$treenode-hover-bg-color !important;
    }
    &.dx-state-focused {
        &> .dx-item.dx-treeview-item { 
            background-color: $treenode-selected-bg-color !important;
        }
        &.dx-state-hover > .dx-item.dx-treeview-item {
            background-color: $treenode-selected-hover-bg-color !important;
        }
    }
}

.dx-treeview-item-with-checkbox.dx-state-focused > .dx-checkbox .dx-checkbox-icon,
.dx-treeview-item-with-checkbox > .dx-checkbox .dx-checkbox-icon {
    border-radius: 4px;
    border-color: $page-font-color;
}

.dx-checkbox-indeterminate .dx-checkbox-icon::before {
    background-color: transparent;
}

.dx-treeview-item-content,
.dx-treeview-toggle-item-visibility {
    color: $page-font-color;
}

.treeview-title .dx-treeview-node {
    padding-left: 0px;
    & .dx-treeview-item h5 {
        margin-bottom: 0px;
    }
}

.treeview-title .dx-treeview-item-without-checkbox{
    &.dx-state-hover > .dx-item.dx-treeview-item {
        background-color:$treenode-hover-bg-color !important;
    }
    &.dx-state-focused {
        &> .dx-item.dx-treeview-item { 
            background-color: $treenode-selected-bg-color !important;
        }
        &.dx-state-hover > .dx-item.dx-treeview-item {
            background-color: $treenode-selected-hover-bg-color !important;
        }
    }
}

.seat-map-passenget-list {
    .dx-treeview-toggle-item-visibility:before {
        font-family: 'Material Icons Outlined';
        content: 'add_box' !important;
        font-size: 16px;
    }
    
    .dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened::before {
        font-family: 'Material Icons Outlined';
        content: 'indeterminate_check_box' !important;
        font-size: 16px;
    }
}

.dx-treelist-rowsview .dx-treelist-expanded span:before {
    font-family: 'Material Icons Outlined';
    content: 'indeterminate_check_box' !important;
    font-size: 16px;
}

.dx-treelist-rowsview .dx-treelist-collapsed span:before {
    font-family: 'Material Icons Outlined';
    content: 'add_box' !important;
    font-size: 16px;
}
.dx-treelist-text-content span.conjunction {
    @include tree-conjunction-word-styles;
}
/* -------------------------------------- */

.dx-treelist-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link,
.dx-treelist-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused), 
.dx-treelist-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused),
.dx-data-row .dx-row-focused {
    background-color: $datagrid-row-hover-color !important;
    color: $page-font-color !important;
}

.dx-treeview-toggle-item-visibility {
    color: $page-font-color !important;
}

.p-treenode > .p-treenode-content:not(.p-treenode-selectable) {
    filter: grayscale(100%);
    opacity: 0.5;
}

.tree-view-departure-icon {
    font-size: 21px;
}

.tree-view-arrival-icon {
    font-size: 21px;
}

.tree-view-seat-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'airline_seat_recline_extra';
}

.tree-view-special-service-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'room_service';
}

.tree-view-emoji-food-beverage-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'emoji_food_beverage';
}

.tree-view-luggage-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'luggage';
}

.tree-view-published-with-changes-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'published_with_changes';
}

.tree-view-pets-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'pets';
}

.tree-view-commute-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'commute';
}

.tree-view-weekend-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'weekend';
}

.tree-view-assignment-turned-in-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'assignment_turned_in';
}

.tree-view-shopping-cart-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'shopping_cart';
}

.tree-view-redeem-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'redeem';
}

.tree-view-percent-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'percent';
}

.tree-view-credit-card-heart-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'sell';
}

.tree-view-currency-exchange-icon::before {
    font-family: 'Material Icons Outlined';
    font-size: 23px;
    content: 'currency_exchange';
}

/* -------- Dx Tree List ---------- */
dx-tree-list {
    .dx-treelist-headers {
        border: none !important;
    }
    .dx-header-row .dx-cell-focus-disabled {
        vertical-align: middle !important;
        border: 1px solid $datagrid-row-border-color !important;
        border-bottom: 2px solid $datagrid-row-border-color !important;
        
    }
    .dx-treelist-rowsview {
        border: none !important;
        td {
            border: 1px solid $datagrid-row-border-color !important;
        }
    }
    &.no-checkbox-header .dx-header-row .dx-cell-focus-disabled {
        padding-left: 7px;
        .dx-select-checkbox {
            visibility: hidden;
        }
    }
}
dx-tree-list .dx-treelist-rowsview td.no-border {
    border-left: none!important;
    border-right: none!important;
    background-color: black;
}
.dx-treelist-container .dx-treelist-headers .dx-header-row > td.no-border{
    border-left: none!important;
    border-right: none!important;
}
dx-tree-list .dx-treelist-rowsview td.no-border-right {
    border-right: none!important;
}
.dx-treelist-container .dx-treelist-headers .dx-header-row > td.no-border-right {
    border-right: none!important;
}
.dx-treelist-content {
    background-color: $body-background-color;
    color: $treelist-text-color;
}
/* -------- Dx Tree List ---------- */