.seatactive{
    color:$color-success;
}
.seatinactive{
    color:$gray-500;
}
.seathidden{
    color:$color-danger;
}
.seatmapselected{
    background:$datagrid-row-selected-color;
}
.male{
    background-image: url("#{$baseURL}img/seat-pax/Male.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 15px;
    cursor: pointer;
}
.female{
    background-image: url("#{$baseURL}img/seat-pax/Female.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 15px;   
    cursor: pointer;
}
.child{
    background-image: url("#{$baseURL}img/seat-pax/Child.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 15px;
    cursor: pointer; 
}
.infant{
    background-image: url("#{$baseURL}img/seat-pax/Infant.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 15px;
    cursor: pointer;   
}
.no-gender{
    background-image: url("#{$baseURL}img/seat-pax/no-gender.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 15px;
    cursor: pointer;   
}
.girl{
    background-image: url("#{$baseURL}img/seat-pax/girl.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 15px;
    cursor: pointer;   
}
.seatmap .tooltip-inner {
    color: $black;
    background-color: $color-warning ;
}

.seat-regular{
    background-image: url("#{$baseURL}img/seat-pax/regular.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 15px;
    cursor: pointer;   
}