.select2-container--select2-builder-gray .select2-selection--single,
.select2-container--select2-builder-gray .select2-selection--multiple {
  border: $input-border-width solid $input-border-color;
}
.select2-container--select2-builder-gray
  .select2-selection--single
  .select2-selection__rendered {
  line-height: calc(
    #{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border}
  );
  padding: 0 $input-padding-x;
}
.select2-container--select2-builder-gray.select2-container--open
  .select2-dropdown.select2-dropdown--below
  .select2-results {
  padding-left: 0px;
  padding-right: 0px;
}
.select2-container--select2-builder-gray
  .select2-search--dropdown
  .select2-search__field {
  display: block;
  width: 100%;
  height: calc(
    #{$line-height-base * 1em} + #{$input-padding-y * 2} + #{$input-height-border}
  );
  padding: $input-padding-y $input-padding-x;
  padding-left: 2.5rem;
  font-weight: $input-font-weight;
  //line-height: calc(#{$line-height-base * 1em} + #{$input-padding-y * 2} + #{$input-height-border});
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  /*border: $input-border-width solid $input-border-color;*/
  border-radius: $input-border-radius;
  box-shadow: inset 0 0.25rem 0.125rem 0 rgba(33, 37, 41, 0.025);

  &:focus {
    border-color: darken($input-border-color, 10%);
  }
}
.select2-container--select2-builder-gray.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--select2-builder-gray.select2-container--open.select2-container--below
  .select2-selection--multiple,
.select2-container--select2-builder-gray.select2-container--open.select2-container--above
  .select2-selection--single,
.select2-container--select2-builder-gray.select2-container--open.select2-container--above
  .select2-selection--multiple {
  position: relative;
  z-index: 1051 + 100;
  border-color: $input-focus-border-color;
}
.select2-container--select2-builder-gray.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--select2-builder-gray.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom-color: transparent;
  border-bottom: 0; //gets rid of the ugly gap
}
.select2-container--select2-builder-gray.select2-container--open.select2-container--above
  .select2-selection--single,
.select2-container--select2-builder-gray.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top-color: transparent;
  border-top: 0; //gets rid of the ugly gap
}
.select2-container--select2-builder-gray.select2-container--focus
  .select2-selection--multiple {
  display: flex;
  align-items: center;
}
.select2-container--select2-builder-gray
  .select2-selection--multiple
  .select2-selection__rendered {
  display: flex;
  padding: 0 0.5rem;
  //clear: both;
  flex-wrap: wrap;
}
.select2-container--select2-builder-gray
  .select2-selection--multiple
  .select2-selection__choice {
  //margin-right: 7px;
  //margin-top: 6px;
  margin: 5px 3px;
  display: inline-flex;
  padding-right: 0;
  align-items: center;

  background: $dropdown-link-active-bg;
  border-color: $primary-400;

  color: $dropdown-link-active-color;

  .select2-selection__choice__remove {
    margin: 0;
    padding-left: 7px;
    padding-right: 7px;
    order: 1;
    font-size: 16px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 5px;

    color: lighten($primary-500, 10%);

    &:hover {
      color: $primary-500;
    }

    &:active {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
    }
  }
}
.select2-container--select2-builder-gray
  .select2-selection--single
  .select2-selection__clear {
  padding: 0 15px;
  margin-right: 8px;
  font-size: 1rem;
  color: $danger-500;

  &:hover {
    color: $danger-600;
  }
}
/* this is not a permanent solution... hopefully IE will die by the time we release version 5.... */
@media all and (forced-colors: none), (forced-colors: active) {
  .select2-container--select2-builder-gray
    .select2-selection--single
    .select2-selection__clear {
    line-height: normal;
    margin-top: 8px;
  }
}
.select2-container--select2-builder-gray
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--select2-builder-gray
  .select2-selection--single
  .select2-selection__arrow {
  height: auto;
  top: 0;
  bottom: 0;
  right: 0.5rem;
  position: absolute;
  width: 20px;
}
.select2-container--select2-builder-gray.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--select2-builder-gray.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--select2-builder-gray
  .select2-results__option[aria-selected="true"] {
  background-color: $select2-dropdown-item-background-color-hover;
  color: $select2-dropdown-item-color-hover;
}
.select2-container--select2-builder-gray
  .select2-results__option--highlighted[aria-selected] {
  background-color: $select2-dropdown-item-background-color-hover;
  color: $select2-dropdown-item-color-hover;
}
.select2-container--select2-builder-gray .select2-results__group {
  padding: 0.5rem 0;
  color: #8e8e8e;
}
/*--------------------------------border color------------------------------------------------*/
.select2-container--select2-builder-gray.select2-container--open
  .select2-dropdown.select2-dropdown--below,
.select2-container--select2-builder-gray.select2-container--open
  .select2-dropdown.select2-dropdown--above {
  border: 1px solid;
  border-color: #ddd;
}
.select2-container--select2-builder-gray.select2-container--open
  .select2-selection--single {
  border: 1px solid $gray-500 !important;
}
/*------------------------place holder color------------------------------------------------*/
.select2-container--select2-builder-gray
  .select2-selection--single
  .select2-selection__placeholder {
  color: $select2-placeholder-color;
}
/*------------------------background focus------------------------------------------------*/
.select2-container--select2-builder-gray.select2-container--focus
  .select2-selection--single,
.select2-container--select2-builder-gray.select2-container--focus
  .select2-selection--multiple {
  border-color: $gray-500;
  background-color: $select2-background-color;
  color: $select2-text-color-active-focus;
  // box-shadow: 0 0 0 0.2rem rgba(253, 57, 149, 0.25);
}
/*-----------------background disabled---------------------------------------------------*/
.select2-container--select2-builder-gray.select2-container--disabled
  .select2-selection--single {
  background-color: rgba(221, 221, 221, 0.5);
  cursor: default;
}
/*---------------------background----------------------------------*/
.select2-container--select2-builder-gray .select2-selection--single {
  background-color: $select2-background-color;
  border-radius: 4px;
  border: 1px solid $gray-500;
  color: $select2-text-color;
  &:hover {
    background-color: $select2-background-color-hover;
  }
  &:focus {
    background-color: $select2-background-color-active-focus;
  }
  &:active {
    background-color: $select2-background-color-active-focus;
  }
}
/*---------------------hide arrow----------------------------*/
.select2-container--select2-builder-gray.select2-container b {
  display: none !important;
}
.select2-container.select2-container--select2-builder-gray {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}