.select2-container--select2-builder-multiple-gray .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--single
  .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--select2-builder-multiple-gray[dir="rtl"]
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}

.select2-container--select2-builder-multiple-gray[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--select2-builder-multiple-gray.select2-container--disabled
  .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--select2-builder-multiple-gray.select2-container--disabled
  .select2-selection--single
  .select2-selection__clear {
  display: none;
}

.select2-container--select2-builder-multiple-gray.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--select2-builder-multiple-gray .select2-selection--multiple {
  background-color: $select2-selection-multiple-background-color; /*------back ground ------*/
  border: none !important; /*---- border ----------*/
  border-radius: 4px;
  cursor: text;
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--multiple
  .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  list-style: none;
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--multiple
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--select2-builder-multiple-gray[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice,
.select2-container--select2-builder-multiple-gray[dir="rtl"]
  .select2-selection--multiple
  .select2-search--inline {
  float: right;
}

.select2-container--select2-builder-multiple-gray[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--select2-builder-multiple-gray[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--select2-builder-multiple-gray.select2-container--focus
  .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--select2-builder-multiple-gray.select2-container--disabled
  .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--select2-builder-multiple-gray.select2-container--disabled
  .select2-selection__choice__remove {
  display: none;
}

.select2-container--select2-builder-multiple-gray.select2-container--open.select2-container--above
  .select2-selection--single,
.select2-container--select2-builder-multiple-gray.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--select2-builder-multiple-gray.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--select2-builder-multiple-gray.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--select2-builder-multiple-gray
  .select2-search--dropdown
  .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--select2-builder-multiple-gray
  .select2-search--inline
  .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
  height: auto !important;
  width: auto !important;
}

.select2-container--select2-builder-multiple-gray
  .select2-results
  > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--select2-builder-multiple-gray
  .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--select2-builder-multiple-gray
  .select2-results__option[aria-disabled="true"] {
  color: #999;
}

.select2-container--select2-builder-multiple-gray
  .select2-results__option[aria-selected="true"] {
  background-color: $select2-dropdown-item-background-color-hover;
  color: $select2-dropdown-item-color-hover;
}

.select2-container--select2-builder-multiple-gray
  .select2-results__option
  .select2-results__option {
  padding-left: 1em;
}
.select2-container--select2-builder-multiple-gray
  .select2-results__option
  .select2-results__option
  .select2-results__group {
  padding-left: 0;
}
.select2-container--select2-builder-multiple-gray
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--select2-builder-multiple-gray
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--select2-builder-multiple-gray
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--select2-builder-multiple-gray
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--select2-builder-multiple-gray
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--select2-builder-multiple-gray
  .select2-results__option--highlighted[aria-selected] {
  background-color: $select2-dropdown-item-background-color-hover;
  color: $select2-dropdown-item-color-hover;
}

.select2-container--select2-builder-multiple-gray .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

/*-------------------------------- custom ---------------------------------------------*/
.select2-container--select2-builder-multiple-gray .select2-selection--single,
.select2-container--select2-builder-multiple-gray .select2-selection--multiple {
  border: $input-border-width solid $input-border-color;
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--single
  .select2-selection__rendered {
  line-height: calc(
    #{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border}
  );
  padding: 0 $input-padding-x;
}
.select2-container--select2-builder-multiple-gray.select2-container--open
  .select2-dropdown.select2-dropdown--below
  .select2-results {
  padding-left: 0px;
  padding-right: 0px;
}
.select2-container--select2-builder-multiple-gray
  .select2-search--dropdown
  .select2-search__field {
  display: block;
  width: 100%;
  height: calc(
    #{$line-height-base * 1em} + #{$input-padding-y * 2} + #{$input-height-border}
  );
  padding: $input-padding-y $input-padding-x;
  padding-left: 2.5rem;
  font-weight: $input-font-weight;
  //line-height: calc(#{$line-height-base * 1em} + #{$input-padding-y * 2} + #{$input-height-border});
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  /*border: $input-border-width solid $input-border-color;*/
  border-radius: $input-border-radius;
  box-shadow: inset 0 0.25rem 0.125rem 0 rgba(33, 37, 41, 0.025);

  &:focus {
    border-color: darken($input-border-color, 10%);
  }
}
.select2-container--select2-builder-multiple-gray.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--select2-builder-multiple-gray.select2-container--open.select2-container--below
  .select2-selection--multiple,
.select2-container--select2-builder-multiple-gray.select2-container--open.select2-container--above
  .select2-selection--single,
.select2-container--select2-builder-multiple-gray.select2-container--open.select2-container--above
  .select2-selection--multiple {
  position: relative;
  z-index: 1051 + 100;
  border-color: $input-focus-border-color;
}
.select2-container--select2-builder-multiple-gray.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--select2-builder-multiple-gray.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom-color: transparent;
  border-bottom: 0; //gets rid of the ugly gap
}
.select2-container--select2-builder-multiple-gray.select2-container--open.select2-container--above
  .select2-selection--single,
.select2-container--select2-builder-multiple-gray.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top-color: transparent;
  border-top: 0; //gets rid of the ugly gap
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--multiple {
  display: flex;
  align-items: center;
}

.select2-container--select2-builder-multiple-gray
  .select2-selection--multiple
  .select2-selection__rendered {
  display: flex;
  padding: 0 0.5rem;
  //clear: both;
  flex-wrap: wrap;
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--multiple
  .select2-selection__choice {
  //margin-right: 7px;
  //margin-top: 6px;
  margin: 3px 5px;
  display: inline-flex;
  padding-right: 0;
  align-items: center;

  background: $select2-selection-choice-background-color;
  border-color: $select2-selection-choice-border-color;;

  color: $select2-selection-choice-text-color;

  .select2-selection__choice__remove {
    margin: 0;
    padding-left: 7px;
    padding-right: 7px;
    order: 1;
    font-size: 16px;
    // border-left: 1px solid rgba(0, 0, 0, 0.1);
    margin-left: 5px;

    color: lighten($primary-500, 10%);

    &:hover {
      color: $primary-500;
    }

    &:active {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
    }
  }
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--single
  .select2-selection__clear {
  padding: 0 15px;
  margin-right: 8px;
  font-size: 1rem;
  color: $danger-500;

  &:hover {
    color: $danger-600;
  }
}
/* this is not a permanent solution... hopefully IE will die by the time we release version 5.... */
@media all and (forced-colors: none), (forced-colors: active) {
  .select2-container--select2-builder-multiple-gray
    .select2-selection--single
    .select2-selection__clear {
    line-height: normal;
    margin-top: 8px;
  }
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--select2-builder-multiple-gray
  .select2-selection--single
  .select2-selection__arrow {
  height: auto;
  top: 0;
  bottom: 0;
  right: 0.5rem;
  position: absolute;
  width: 20px;
}
.select2-container--select2-builder-multiple-gray.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--select2-builder-multiple-gray.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--select2-builder-multiple-gray
  .select2-results__option[aria-selected="true"] {
  background-color: $select2-dropdown-item-background-color-hover;
  color: $select2-dropdown-item-color-hover;
}
.select2-container--select2-builder-multiple-gray
  .select2-results__option--highlighted[aria-selected] {
  background-color: $select2-dropdown-item-background-color-hover;
  color: $select2-dropdown-item-color-hover;
}
.select2-container--select2-builder-multiple-gray .select2-results__group {
  padding: 0.5rem 0;
  color: #8e8e8e;
}
/*------------------------border color------------------------------------------------*/
.select2-container--select2-builder-multiple-gray.select2-container--open
  .select2-dropdown.select2-dropdown--below,
.select2-container--select2-builder-multiple-gray.select2-container--open
  .select2-dropdown.select2-dropdown--above {
  border: 1px solid;
  border-color: #ddd;
}
/*------------------------place holder color for multiple------------------------------------------------*/
.select2-container--select2-builder-multiple-gray
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-search.select2-search--inline
  input::-webkit-input-placeholder {
  color: #999;
  font-size: 14px !important;
}

/*------------------------place holder color------------------------------------------------*/
.select2-container--select2-builder-multiple-gray
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}
/*------------------------background focus------------------------------------------------*/
.select2-container--select2-builder-multiple-gray.select2-container--focus
  .select2-selection--single,
.select2-container--select2-builder-multiple-gray.select2-container--focus
  .select2-selection--multiple {
  border-color: $input-focus-border-color;
  background-color: rgba(221, 221, 221, 0.5);
  color: rgb(255, 255, 255) !important;
  // box-shadow: 0 0 0 0.2rem rgba(253, 57, 149, 0.25);

  &:hover {
    background-color: rgba(221, 221, 221, 0.9);
    // box-shadow: 0 0 0 0.2rem rgba(253, 57, 149, 0.25);
  }
  &:focus {
    background-color: rgb(192, 192, 192);
    // box-shadow: 0 0 0 0.2rem rgba(253, 57, 149, 0.25);
  }
  &:active {
    background-color: rgb(192, 192, 192);
    // box-shadow: 0 0 0 0.2rem rgba(253, 57, 149, 0.25);
  }
}
/*-----------------background disabled---------------------------------------------------*/
.select2-container--select2-builder-multiple-gray.select2-container--disabled
  .select2-selection--single {
  background-color: rgba(221, 221, 221, 0.5);
  cursor: default;
}
/*---------------------background----------------------------------*/
.select2-container--select2-builder-multiple-gray .select2-selection--single {
  background-color: rgba(221, 221, 221, 0.5);
  border-radius: 4px;
  border: none;
  color: #495057 !important;
  &:hover {
    background-color: rgba(221, 221, 221, 0.9);
  }
  &:focus {
    background-color: rgb(192, 192, 192);
  }
  &:active {
    background-color: rgb(192, 192, 192);
  }
}
/*---------------------hide arrow----------------------------*/
.select2-container--select2-builder-multiple-gray.select2-container b {
  display: none !important;
}

/*------------------disabled multiple ------------------------*/
.select2-container--select2-builder-multiple-gray.select2-container--disabled
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  padding-right: 3px !important;
}
.select2-container.select2-container--select2-builder-multiple-gray, .select2-search__field {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}