.mat-menu-panel {
    width: 200px;
    .mat-section {
        border-top: 1px solid $gray-300;
    }
}
.categories {
    .categories-item {
        cursor: pointer
    }
}