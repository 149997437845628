 .status-dropdown-nostatus {
    .select2-selection {
      @extend .status-dropdown-selection-operational;
      .select2-selection__rendered {
        @extend .status-dropdown-badge-operational;
        background-color: $status-dropdown-color-nostatus;
      }  
    }
  }
  
  .status-dropdown-arrived {
    .select2-selection {
      @extend .status-dropdown-selection-operational;
      .select2-selection__rendered {
        @extend .status-dropdown-badge-operational;
        background-color: $status-dropdown-color-arrived;
      }
    }
  }
  
  .status-dropdown-cancelled {
    .select2-selection {
      @extend .status-dropdown-selection-operational;
      .select2-selection__rendered {
        @extend .status-dropdown-badge-operational;
        background-color: $status-dropdown-color-cancelled;
      }
    }
  }
  
  .status-dropdown-delayed {
    .select2-selection {
      @extend .status-dropdown-selection-operational;
      .select2-selection__rendered {
        @extend .status-dropdown-badge-operational;
        background-color: $status-dropdown-color-delayed;
      }
    }
  }

  .status-dropdown-delivered {
    .select2-selection {
      @extend .status-dropdown-selection-operational;
      .select2-selection__rendered {
        @extend .status-dropdown-badge-operational;
        background-color: $status-dropdown-color-delivered;
      }
    }
  }

  .status-dropdown-departed {
    .select2-selection {
      @extend .status-dropdown-selection-operational;
      .select2-selection__rendered {
        @extend .status-dropdown-badge-operational;
        background-color: $status-dropdown-color-departed;
      }
    }
  }
 
  .status-dropdown-operating-inactive {
    .select2-selection {
      @extend .status-dropdown-selection-operational;
      .select2-selection__rendered {
        @extend .status-dropdown-badge-operational;
        background-color: $status-dropdown-color-inactive;
      }
    }
  }

  .status-dropdown-badge-operational {
    width: 50%;
    max-width: 90px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    height: 25px; 
    color: $status-dropdown-color !important;
    border-radius: 25px;
    font-size: 100%;
    float: left;
    text-align: center;
    margin-top: 6px;
    line-height: 25px !important;
  }

  .status-dropdown-selection-operational {
    border-color: #d3d3d3 !important;
  }
  
  .status-operational-dropdown-selector {
    .select2-results{
      padding: 0;
      .select2-results__option{
        padding-left: 15px;
        padding-right: 15px;
        line-height: 25px !important;
        height: 40px;
      }
    }
  }

  .badge-pill {
    color: $status-dropdown-color!important;
  }