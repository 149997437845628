.shadow-top,
.shadow-bottom,
.mobile-view-activated.header-function-fixed .page-header,
.mobile-view-activated.header-function-fixed .page-footer {
	&:after {
		content: "";
		height: 6px;
		position: absolute;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPYAAAAICAMAAAD9VPKTAAAATlBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADEoqZZAAAAGnRSTlMACRVXPCMeNMEsdZ98SZBDbFDIrZhkXreCiM2g9MAAAAD1SURBVDjLzZHbDoIwEAVdEC94raLi//+otD1Lu4tgCWqYhPC0J5np4jeQ+2gKYWZukCLrYTWKvpVOl/9AAeVUMNuWtWA5AnkZFgtGlaHA901jTeEYa50cB4/x5A6Tp2H478GU342ziBJRBsqmNIAqaVWIwpMVW7l7w81y9pSS/QdKCUbcoF1GEMTgDm0ETqALpPraA6nLskKVRWEZzOq6fjYcQQV2CVSAb+1OMxeaoANHEAk4gNQn6A+/sBDu+kayrApPKMLuCh6ezQhwwgvogRSowAmiAG/ttXyPs35lLW0MpNOstfJlEK2e5g1xY7S4fnUPzF+TRjAMoku43AAAAABJRU5ErkJggg==);
	 -webkit-background-size: cover;
	    -moz-background-size: cover;
	      -o-background-size: cover;
	         background-size: cover;
		top: -5px;
		left: 0;
		right: 0;
		opacity: 0.06;		    	
	}
}

.shadow-bottom,
.mobile-view-activated.header-function-fixed .page-header {
	&:after {
		top: auto;
		bottom: -5px;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPYAAAAICAMAAAD9VPKTAAAANlBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC3dmhyAAAAEnRSTlMACRUgPnjDV51MNCxhkIVsrbfUWo5iAAAA/UlEQVQ4y8zR7WqEMBCF4Xy4HeNos73/m605M+HQlIB2oeyjP5XwngQTXTLZqOp2WqCAiOxQa32Yz+Zo1u5pvpqPP8CPT7N2R4PDHqbWuoOIFFhgO6lqNslEFwaoHsKV4cXCpZl3H+z2cLidjGjozfNqaX5F6xANYSKO8aDjtXMAqJwAK8Dh1hXvNf41oJKdUL2VsZNeBp8Pii+Inp9cdsoBTHECe1fN4yXV7J1AYSpjUesSeyHcF8EXcLlT2HwFKj+IKXJNYRwt4LZGITO1i8Dc10VKjjNwCa5Byw0beR8bmUmRwv+IgzSRb0kTcRDezfcYwRhEUAKgxtAEAACGmyM6KW9inQAAAABJRU5ErkJggg==)
	}
}

.shadow-bottom-line,
.shadow-top-line {
	position: relative;
	&:after {
		content: "";
		width: 100%;
		height: 5px;
		position: absolute;
		bottom: -5px;
		left: 0;
		right: 0;
		@include gradient-img(rgba($fusion-500, 0.09), transparent)

		/*background-image: -webkit-linear-gradient(top, rgba($fusion-500, 0.09), transparent);
		background-image: linear-gradient(top, rgba($fusion-500, 0.09), transparent);*/
	}
}

.shadow-top-line {
	&:after {
		content: "";
		top: -5px;

		@include gradient-img(transparent, rgba($fusion-500, 0.09))

		/*background-image: -webkit-linear-gradient(top, transparent, rgba($fusion-500, 0.09));
		background-image: linear-gradient(top, transparent, rgba($fusion-500, 0.09));*/
	}	
}

/* press animation */
.press-scale-down {
	//transition: transform 0.2s ease;

	@include transition (0.2s,ease);

	&:active {
		@include scale(0.95);
	}
}

.hover-white {
	&:hover {
		background-image: linear-gradient(rgba(29,33,41,.03),rgba(29,33,41,.04));
	}
	&:active {
		background: darken(lighten($black, 95.5%), 5%);
	}
}