.panel-hdr [class*='passenger-'],
.dx-row.dx-data-row.dx-row-lines.dx-column-lines td [class*='passenger-'] {
    background-size: 100%;
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
    padding: 0px;
    cursor: pointer;
    margin-right: 10px;
}
.passenger-male {
    content: url("#{$baseURL}img/seat-pax/Male.png");
    background-image: url("#{$baseURL}img/seat-pax/Male.png");

}
.passenger-female {
    content: url("#{$baseURL}img/seat-pax/Female.png");
    background-image: url("#{$baseURL}img/seat-pax/Female.png");
}
.passenger-child {
    content: url("#{$baseURL}img/seat-pax/Child.png");
    background-image: url("#{$baseURL}img/seat-pax/Child.png");
}
.passenger-infant {
    content: url("#{$baseURL}img/seat-pax/Infant.png");
    background-image: url("#{$baseURL}img/seat-pax/Infant.png");    
}
.passenger-girl {
    content: url("#{$baseURL}img/seat-pax/girl.png");
    background-image: url("#{$baseURL}img/seat-pax/girl.png");    
}
.passenger-no-gender {
    content: url("#{$baseURL}img/seat-pax/no-gender.png");
    background-image: url("#{$baseURL}img/seat-pax/no-gender.png");
}
.tree-avatar {
    background-image: none !important;
    max-height: 25px;
    margin-right: 10px;
}
.panel-seat-map [class*='shopping-seat-']{
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 15px;
    cursor: pointer;   
    width: 30px;
    height: 30px;
}
.shopping-seat-regular {
    background-image: url("#{$baseURL}img/shopping-seat-pax/regular.svg");    
}
.shopping-seat-selected {
    background-image: url("#{$baseURL}img/shopping-seat-pax/selected.svg");  
}
.shopping-seat-up-front {
    background-image: url("#{$baseURL}img/shopping-seat-pax/up_front.svg");  
}
.shopping-seat-not-available {
    background-image: url("#{$baseURL}img/shopping-seat-pax/not_available.svg");  
}
.shopping-seat-extra-leg-room {
    background-image: url("#{$baseURL}img/shopping-seat-pax/extra_leg_room.svg");  
}
.shopping-seat-already-reserved {
    background-image: url("#{$baseURL}img/shopping-seat-pax/already_reserved.svg");  
}
.shopping-seat-legend {
    display: flex;
}
.no-pointer {
    cursor: default !important;
}