.page-content {
	//align-items: stretch;
    flex: 1 1 auto;
    order: 3;

    display: flex;
    flex-direction: column;
    position: relative;

    //min-height: calc(100vh - #{$header-height + $footer-height}) //spoonfeeding IE
}

.page-font-color {
    color: $page-font-color;
}
/*@include media-breakpoint-up($mobile-breakpoint) {

	.nav-function-top {
		.page-content {
			min-height: calc(100vh - #{$header-height-nav-top + $nav-top-height + $footer-height})
		}
	}

}

*/