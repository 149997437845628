/* nav tabs panel */
.nav-tabs-clean {

	height: $nav-tabs-clean-link-height;
	
	.nav-item {

		.nav-link {
			border-radius: 0;
			border: 0;
			height: $nav-tabs-clean-link-height;
			padding: 10px 0px;
			margin-right: 30px;
			/*font-size: rem($fs-md);
			font-weight: 500;*/

			display: flex;
			align-items: center;
			border-bottom: 1px solid transparent;
			color: $page-font-color;
			&.active {
				border-bottom: 2px solid $primary-500;
				color: $page-font-color;
				font-weight: 500;
				&.with-status-icon {
					width: calc(100% - 53px);
				}
			}

			&:hover {
				background-color: $nav-navigate-tab-hover-background;
			}
		}
	}
}

.nav-tabs-fill {

	height: $nav-tabs-fill-link-height;
	.nav-item {
		text-align: center;
		.nav-link {
			border-radius: 0;
			border: 0;
			height: $nav-tabs-fill-link-height;
			padding: 10px;
			/*font-size: rem($fs-md);
			font-weight: 500;*/

			display: flex;
			align-items: center;
			border-bottom: 1px solid transparent;
			color: $black;
			background-color: $color-on-primary;
			font-weight: 500;
			border-left: 1px solid $white;
			&.active {
				background-color: $white;
				color: $color-primary;
				border-top: 1px solid $color-primary;
				&.with-status-icon {
					width: calc(100% - 53px);
				}
			}

			&:hover {
				background-color: $color-primary;
				color: $white;
			}
		}
	}
}

/* hack for waves effect breaking tabs */
/*.nav-tabs .nav-item > .nav-link.waves-effect {
	height: 100% !important;
	display: block;
}*/

/* fontsize for tabs */
.nav-tabs .nav-item .nav-link {
	&:not(:hover) {
		color: inherit;
	}

	&.active:not(:hover) {
		color: #333;
	}
	&:hover:not(.active) {
		color: inherit;
	}
}

.nav .nav-link:not([class^="btn-"]):not([class*=" btn-"]) {
	font-size: rem($fs-base + 3);
}

.nav-tabs .nav-link.active, 
.nav-tabs .nav-item.show .nav-link {
	color: $primary-500;
}

.freeze-tabs {
	position: sticky !important; 
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 15px;
	top: 140px;  
	z-index: 10; 
	.subheader {
			margin-bottom: 15px;
	}
}

.freeze-tabs-scroll {
	//border-bottom: 1px solid #D3D3D3;
	background-color: $page-bg
}