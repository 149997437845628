
// ----------------------------- Passenger List--------------------------------------

.flight-seatmap-passenger-pannel {
    width: Fill (536.5px)px;
    height: Fixed (961px)px;
    padding: 0px 10px 15px 1px;
    gap: 0px;
    border-radius: 0px 0px 4px 4px;
    border: 0px 1px 1px 1px;
    opacity: 0px;
}
.flight-seatmap-flight-level {
    height: 30px;
    display: flex;
    align-items: center;
    gap: 0px;
    opacity: 0px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.25px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $passenger-list-flight;
}
.flight-seatmap-passenger-container {
    height: 34px;
    display: flex;
    align-items: center;
}
.flight-seatmap-passenger-level {
    
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $panel-active-border-color;
    .avatar {
        width: 20px;
        height: 20px;
        padding: 1.71px 0px 0px 0px;
        gap: 0px;
        border-radius: 100px 0px 0px 0px;
        border: 1px 0px 0px 0px;
        opacity: 0px;
    }
}
.flight-seatmap-passenger-image-container {
    margin-left: 14px;
    .avatar {
        width: 23px;
        height: 23px;
        gap: 0px;
        opacity: 0px;
        border-radius: 50px;
    }
}

.flight-seatmap-attribute-level {
    
    width: 401.5px;
    gap: 0px;
    opacity: 0px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.25px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $passenger-list-attribute;
    height: 26px;
    align-items: center;
}

.flight-seatmap-passenger-price-container {
    width: 97px;
    height: 20px;
    gap: 0px;
    opacity: 0px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $passenger-list-attribute;
    margin-top: -1px;
}
.flight-seatmap-passenger-seperator-container {
    width: 4px;
    height: 20px;
    gap: 0px;
    opacity: 0px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $passenger-list-seperator;
}

.flight-seatmap-treeview .dx-treeview-toggle-item-visibility {
    margin-top: 7px;
}

// ----------------------------- Seat legent--------------------------------------
.seat-selected {
    content: url("#{$baseURL}img/seat-legend/selected.svg");
    background-image: url("#{$baseURL}img/seat-legend/selected.svg");
}
.seat-additional-charge {
    content: url("#{$baseURL}img/seat-legend/additional_charge.svg");
    background-image: url("#{$baseURL}img/seat-legend/additional_charge.svg");
}
.seat-already-reserved {
    content: url("#{$baseURL}img/seat-legend/already_reserved.svg");
    background-image: url("#{$baseURL}img/seat-legend/already_reserved.svg");
}
.seat-exit {
    content: url("#{$baseURL}img/seat-legend/exit.svg");
    background-image: url("#{$baseURL}img/seat-legend/exit.svg");
}
.seat-gratis {
    content: url("#{$baseURL}img/seat-legend/gratis.svg");
    background-image: url("#{$baseURL}img/seat-legend/gratis.svg");
}
.seat-not-available {
    content: url("#{$baseURL}img/seat-legend/not_available.svg");
    background-image: url("#{$baseURL}img/seat-legend/not_available.svg");
}
.chargeable-extra-legroom {
    content: url("#{$baseURL}img/seat-legend/seat_chargeable-extra-legroom.svg");
    background-image: url("#{$baseURL}img/seat-legend/seat_chargeable-extra-legroom.svg");
}
.gratis_exit {
    content: url("#{$baseURL}img/seat-legend/gratis_exit.svg");
    background-image: url("#{$baseURL}img/seat-legend/gratis_exit.svg");
}
.legend-text {
    gap: 0px;
    opacity: 0px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $passenger-list-attribute;
}
.legend-container {
    border-bottom: 1px solid $passenger-list-seperator;
}

.transport-seatmap-layout {
    padding: 25px;
    .seat-row {
        padding: 2px;
        justify-content: center;
    }
    .label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: $seat-layout-lebel-color;
    }
    .seat {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .fw-50 {
        width: 50px;
    }
    .fw-100 {
        width: 100px;
    }
    .seat-occupied-icon {
        height: 40px;
        position: absolute;
        border-radius: 40px;
    }
}

