.action-bar {
  .back-arrow {
    color: $action-bar-back-icon-color;
  }
  > div {
    > ul {
      > li {
        > a {
          .refresh-icon {
            color: $action-bar-refresh-icon-color;
          }
        }
      }
    }
  }
  background-color: $body-background-color;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  height: 45px;
  > a {
    width: 5px;
  }
  position: fixed !important;
  top: 95px;
  width: 100%;
  z-index: 21;
  .navbar-collapse {
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    .action-bar-icon {
      font-size: $action-bar-icon-size;
      height: $action-bar-icon-size;
      width: $action-bar-icon-size;
    }

    /* space between icon and text */
    .nav-item {
      .nav-link {
        .page-font-color {
          padding-left: 5px;
        }
      }
    }
  }
}

/*-- Panel Header Button --*/
.btn.btn-action-bar {
  font-family: Roboto;
  font-size: 16px;
  border-radius: 0%;
  padding: 8px !important;
  margin-left: 0px;
  &:not(:disabled):hover {
    background-color: $panel-header-button-background-color-hover;
    .page-font-color {
      color: $panel-header-button-color-hover !important;
    }
  }
  &:not(:disabled):active {
    box-shadow: none !important;
    background-color: $white;
  }
  &:disabled {
    color: $gray-500 !important;
    opacity: 0.5;
    mat-icon {
      opacity: 0.5;
    }
  }
  mat-icon {
    margin-right: 5px;
  }
}

.date-time-label {
  position: fixed;
  right: 0;
  width: 230px;
  padding: 18px 18px 14px;
  transition: all 0.145s ease-out;
}
