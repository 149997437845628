/* modal shadow */
.modal-content {
	box-shadow: 0 0 20px 0 rgba($black, 0.2); 
}

/* adjustments */
.modal-dialog {
	.modal.show & {		
		transform: none;		
	}
}

/* alert modal */
.modal-alert {
	padding: 0 !important; /* overriding bootstrap generated style */

	.modal-dialog {
		max-width: 100% !important; /* overriding bootstrap css for all media queries */
		display: flex;
		align-items: center;
		min-height: calc(100% - 3.5rem);
		transform: none !important; /* overriding bootstrap css */
	}

	.modal-content {
		background-color: rgba($black,0.8);
		border-radius: 0;
		padding: 1.5rem 1rem 1rem;
	}

	.modal-title {
		font-size: 1.5rem;
    	font-weight: 300;

		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.modal-header,
	.modal-body,
	.modal-footer {
		/*left: 20%;
		width: 60%;*/
		width: 100%;
		max-width: map-get($grid-breakpoints, lg);
		margin: 0 auto;
		padding: 0;
		color: $white;
		position: relative;
	}

	/*.modal-body {
		padding: 0.5rem 0;
	}
*/
	.close {
		color: $white;
		text-shadow: 0 1px 0 $black;
	}
}

/* transparent modal */
.modal-transparent {
	.modal-content {
		box-shadow: 0 1px 15px 1px rgba($primary-900, 0.3);
	}
	.modal-content {
		background: rgba(desaturate(darken($primary-800, 25%), 20%), 0.85); 
	}
}

/* transparent backdrop */
.modal-backdrop-transparent {
	background: transparent;
}

/* fullscreen modal */
.modal-fullscreen {
	
	padding: 0 !important;

	.modal-content {
		border-radius: 0;
		border-width: 0;
	}

	.modal-dialog {
		max-width: unquote("calc(100vw - 40px)");
		max-height: unquote("calc(100vh - 80px)");

		.modal-content {
			height: unquote("calc(100vh - 80px)");
		}
	}

}


/* top */
.modal-dialog-top,
.modal-dialog-bottom {
		position: absolute;
		top: 0;
		margin: 0;
		min-width: 100%;
	
	.modal-dialog {
		width: 100%;
		max-width: 100%;
		margin: 0;
	}

	.modal-content {
		border-radius: 0px;
		border:0;
	}
}

.modal-dialog-bottom {
	top: auto;
	bottom: 0;

	.modal.fade & {
		transform: translate(0,25%);
	}
}

/* left */
.modal-dialog-left {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	margin: 0 !important;

	.modal-content {
		min-height: 100%;
		border-width: 0;
		border-radius: 0;
	}

	.modal.fade & {
		transform: translate(-25%, 0);
	}
}

/* right */
.modal-dialog-right {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	margin: 0 !important;
	border-width: 0px;

	.modal-content {
		min-height: 100%;
		border-width: 0;
		border-radius: 0;
	}

	.modal.fade & {
		transform: translate(25%, 0);
	}
}

.modal.show .modal-dialog {
	transform: translate(0, 0);
}

/* modal size */
.modal-md {	max-width: 350px; }


/*Seat Selection Confirmation Modal*/
.seatmap-confirmation {
    .modal-content {
        background-color: $modal-seat-selection-confirmation-background-color;
    }
    .primary-info-container {
        background-color: $modal-seat-selection-confirmation-seat-number-primary-container-background-color;
        border-radius: 8px;
        border: 1px solid $modal-seat-selection-confirmation-seat-number-primary-container-border-color;
        padding: 8px;
    
        .seat-number-container {
            display: flex;
            align-items: center;
            padding-right: 4px;
    
            .seat-number {
                height: 32px;
                width: 32px;
                text-align: center;
                background-color: #B1B1B1;
                align-content: center;
                display: grid;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border: 1px solid #1D1C1C;
                color: #1D1C1C;
            }
        }
    
        .product-info-container {
            padding-left: 4px;
    
            .product-name {
                color: $modal-seat-selection-confirmation-primary-text-color;
                white-space: nowrap;
                font-weight: 500;
                font-size: 16px;
            }
    
            .product-price {
                font-weight: 400;
                font-size: 14px;
                white-space: nowrap;
                color: $modal-seat-selection-confirmation-text-color;
            }
        }
    }
    
    .seat-property-container {
        margin-top: 16px;
    
        .seat-property-item {
            color: $modal-seat-selection-confirmation-text-color;
            font-weight: 500;
            margin-bottom: 0.5rem;
        }
    
        .seat-property-item::before {
            content: '• ';
            color: $modal-seat-selection-confirmation-text-color;
            font-weight: 500;
        }
    }
    
    .seat-image {
        width: 100%;
        height: auto;
    }
    
    .modal-body {
        padding-top: 0;
    }
    
    .modal-header {
        h4 {
            font-size: 20px;
            color: $modal-seat-selection-confirmation-text-color;
        }
    }

    .btn-modal-cancel {
        background-color: $modal-seat-selection-cancel-button-background-color;
        border-color: $modal-seat-selection-cancel-button-background-color;
        color: $modal-seat-selection-confirmation-text-color;
    }
}
	 