.pagination {
	.page-item:not(:first-child) {
		margin-left: 0.4rem;
	}

	.page-item:first-child:not(.active),
	.page-item:last-child:not(.active),
	.page-item.disabled {
		.page-link {
			background-color: $datagrid-row-hover-color;
			border: none;
			color: $page-font-color;
		}
	}

	.page-item.active .page-link {
		background-color: $datagrid-row-hover-color !important;
		border: none !important;
		color: $page-font-color !important;
	    cursor: default;
	}

	.page-link {
		border-radius: $border-radius;
		background-color: $page-bg !important;
		border-color: $page-font-color !important;
		color: $page-font-color !important;
		margin-left: 7px;
		&:hover {
			background-color: $page-bg !important;
			border-color: $page-font-color;
			color: $page-font-color;
		}
	}

	&.pagination-xs {
		.page-link {
			padding: 0.2rem 0.5rem;
			font-size: rem($fs-xs)
		}
	} 

}


